import React from "react";

// Customizable Area Start
import {
  Grid,
  Container,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  Checkbox,
  Paper,
  Typography,
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeProfile, keasyIcon, profileMain, projects, activeProjects, bottomImageIcon } from "./assets";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
export const invoice = require("../../invoicebilling/assets/invoice.png");
export const approved = require("../../invoicebilling/assets/approved.png");
export const keyMainChain = require("../../invoicebilling/assets/key.png");
export const activeKeyMain = require("../../invoicebilling/assets/activeKey.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "Task",
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Grid container direction="row" wrap="nowrap" 
            sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
        >
          <PopupMessage
                open={this.state.messageOpen}
                type={this.state.messageType}
                message={this.state.message}
                handleClose={this.handlePopupMessageClose}
              />
          <Grid item sx={webStyles.navBarItem}>
            <NavigationSidebar
              data-test-id={"navId"}
              activeItem={this.state.activeVendorItem}
              sidebarItems={[
                { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                { label: configJSON.invoiceLable, labelKey: configJSON.invoiceBlock, icon: invoice, activeIcon: invoice,pathName:configJSON.invoiceBlock },
                { label: configJSON.ApprovedOwnersLable, labelKey: configJSON.ApprovedOwnersBlock, icon: approved, activeIcon: approved,pathName:configJSON.ApprovedOwnersBlock },
                { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
              ]}
              onClickSidebar={this.handleVendoritemClick}
              keasylogo={keasyIcon}
              openDrawer={this.state.openVendorDrawer}
              onClickDrawer={this.handleVendorDrawer}
            />
          </Grid>
          <Grid item sx={webStyles.mainContent}>
          <Container maxWidth={false} sx={webStyles.mainContainer}
            >
              <CustomNavbar 
                {...this.screenProps}
                showChatButton={true}   
                showPropertiesButton={false} 
              />
                <Typography sx={webStyles.vendor_profile_header} paragraph  style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box>Work Order Details</Box>
                  <Button sx={webStyles.goBackBtn} style={{ textTransform: 'none', marginTop: "15px" }} id="backBtnTest" onClick={this.navigateToTasks}>Go Back</Button>
                </Typography>
                
                <Paper elevation={3} sx={webStyles.contentPaper}>
                  <Grid container mt={2} ml={5}>
                    <Grid item md={6} xs={12}>
                      <Grid container>
                        <Grid item xs={6} md={4} mb={5}>
                          <Typography sx={{ ...webStyles.standardText, fontWeight: 700, marginRight: "15px" }}>ORDER ID</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography sx={{ ...webStyles.standardText, fontWeight: 700, marginRight: "15px", wordBreak: 'break-word' }}>
                            #{this.orderId}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>Date opened</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.date}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Requested by
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8} sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.requestedBy}
                          </Typography>
                          <Typography sx={webStyles.typeBadge}>
                            {this.state.workOrder.userType}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Category
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.category}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Work needed
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.workNeeded}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Assigned vendor
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.assignedVendor}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Description
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Price
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ ...webStyles.standardText, wordBreak: 'break-word' }}>
                            {this.state.workOrder.projectAmount}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Vendor update
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <RadioGroup
                            defaultValue={this.state.statusUpdate}
                            aria-labelledby="statusUpdate"
                            name="statusUpdate"
                            value={this.state.statusUpdate}
                            onChange={this.handleInputChange}
                          >
                            <FormControlLabel
                              value="work_completed"
                              id="completed"
                              control={<CustomRadioButton />}
                              label="Mark project as completed"
                              sx={{ marginBottom: "16px",
                                '& .MuiFormControlLabel-label': {
                                  ...webStyles.standardText
                                }
                               }}
                            />
                            <FormControlLabel
                              value="work_in_progress"
                              id="inProgress"
                              control={<CustomRadioButton />}
                              label="Mark project as in progress"
                              sx={{ marginBottom: "16px",
                                '& .MuiFormControlLabel-label': {
                                  ...webStyles.standardText
                                }
                               }}
                            />
                            <FormControlLabel
                              value="awaiting_payment"
                              id="awaitingPayment"
                              control={<CustomRadioButton />}
                              label="Mark project as awaiting payment"
                              sx={{ marginBottom: "16px",
                                '& .MuiFormControlLabel-label': {
                                  ...webStyles.standardText
                                }
                               }}
                            />
                            <FormControlLabel
                              value="paid"
                              id="paid"
                              control={<CustomRadioButton />}
                              label="Mark project as paid"
                              sx={{ marginBottom: "16px",
                                '& .MuiFormControlLabel-label': {
                                  ...webStyles.standardText
                                }
                               }}
                            />
                          </RadioGroup>
                        </Grid>
                        <FormControlLabel
                          label="I agree with the disclaimer (insert disclaimer text here)"
                          control={<Checkbox
                                      checked={this.state.agreeCheckbox} 
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({agreeCheckbox: e.target.checked})
                                      }}
                                      sx={{
                                        '&.Mui-checked': {
                                          color: "#DAA520",
                                        },
                                      }}
                                  />}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              ...webStyles.standardText,
                              marginTop: '5px'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"end"} sx={{ padding: "25px" }}>
                    <Button
                      size="medium"
                      id="sendButton"
                      type="submit"
                      sx={webStyles.form_button}
                      onClick={this.onStatusUpdateSubmit}
                    >
                      Update status
                    </Button>
                  </Box>
                </Paper>
                <img
                  src={bottomImageIcon}
                  alt="Description"
                  style={{
                      position: "absolute",
                      bottom: "-15px",
                      right: "0"
                  }}
                />
            </Container>
          </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  navBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000,
    "@media(max-width: 992px)": {
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    },
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  vendor_profile_header: {
    fontSize: "24px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#000000",
  },
  contentPaper: {
    border: "2px solid #FFD466",
    borderRadius: "8px",
    borderBottomRightRadius: "30px",
    padding: "20px",
    boxShadow: "unset",
  },
  mainContent: {
    flexGrow: 1,
    "@media (min-width: 992px)": {
        borderLeft: "1px solid rgb(208,203,199)",
    },
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginLeft: {md: "120px", sm: "0px", xs: "0px"}
  },
  mainContainer: {
    position: "relative",
    paddingBottom: "100px",
    minHeight: "100vh",
  },
  standardText: {
    fontSize: "16px",
    fontFamily: "Outfit",
  },
  regularText: {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#BAB1AB",
    marginRight: "15px",
  },
  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFF1CC",
    },
  },
  typeBadge: {
    position: "relative",
    display: "flex",
    fontSize: "16px",
    fontFamily: "Outfit",
    wordBreak: 'break-word',
    marginLeft: "16px",
    backgroundColor: "#FFF1CC",
    flexBasis: "25%",
    height: "75%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    top: "-5px",
    textTransform: "capitalize",
  },
  goBackBtn: {
    width: "80px",
    height: "32px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "14px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
  },
};
// Customizable Area End
