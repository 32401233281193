import React from "react";

// Customizable Area Start
import { 
  Box,
  Typography ,
  styled, 
  Grid,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Select,
  Chip} from "@mui/material";
  import {  filterIcon,deleteImg } from './assets';
import {  ArrowDropDown, ArrowDropUp, MoreVert } from '@mui/icons-material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const accountsMain = require("../../customform/assets/profile.png");
export const Service = require("../../appointmentmanagement/assets/service.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");

export const ordersMain = require("../../customform/assets/order.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeOrderFinancial = require("../../customform/assets/activeFinancial.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const projects = require("../../customform/assets/projects.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const checkButton = require("../../appointmentmanagement/assets/checklist.png");
export const activeProjects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
const deleteIcon = require("../../settings2/assets/cross.svg");

import {documentActive,documents } from '../../automaticreminders/src/assets';
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
// Customizable Area End
import LandlordServiceRequestsDashboardController, {
  PropertiesDropdown,
  Props,
  configJSON,
} from "./LandlordServiceRequestsDashboardController.web";

export default class LandlordServiceRequestsDashboard extends LandlordServiceRequestsDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "LandlordServiceRequestsDashboard",
  };
   renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" justifyContent="center">
          <Typography fontSize="12px" fontWeight="700" style={{fontFamily: "Outfit",}}>{label}</Typography>
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id="sortUpId"
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id="sortDownId"
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };
  renderUnit = (selectedProperties: any, property: any, selectedUnits: any) => {
    return (
      property.units?.map((unit: any, unitIndex: number) => (
        <>
        <Grid container spacing={2} mt={2}>
          <GridItem item xl={6} lg={6} md={6} sm={12}>
            <HeadItems>
              <HeadName>{property.property_name.toUpperCase()}-{unit.name.toUpperCase()}</HeadName>
              <Typography sx={{
                fontFamily:"Outfit",
                fontSize:"16px",
                fontWeight:400,
              }}>{property.address}</Typography>
            </HeadItems>
          </GridItem>
          </Grid>
          {unit.tenant_service_requests && unit.tenant_service_requests.length > 0 && (
          <BorderContainer key={unit.unit_id}>
            <TableContainer>
              <Table data-test-id="tableHead">
                <TableHead>
                  <TableRow>
                    <TableCell>
                    {this.renderSortableTableCell("tenantStatus", "ID", unitIndex)}
                    </TableCell>
                    <TableCell>
                    {this.renderSortableTableCell("name", "Category", unitIndex)}
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Work Needed</CustomTableCell>
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Due Date</CustomTableCell>
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Description</CustomTableCell>
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Request By</CustomTableCell>
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Status</CustomTableCell>
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Availability</CustomTableCell>
                    </TableCell>
                    <TableCell>
                    <CustomTableCell>Review</CustomTableCell>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead >
                <TableBody
                 data-test-id="TableBody"
                >
                  {unit.tenant_service_requests.map((request: any) => (
                    <TableRow key={request.id}>
                      <CustomDetailTableCell>{request.id}</CustomDetailTableCell>
                      <CustomDetailTableCell>{request.sub_category?.name || "N/A"}</CustomDetailTableCell>
                      <CustomDetailTableCell>{request.work_needed}</CustomDetailTableCell>
                      <CustomDetailTableCell>{this.convertTime(request.due_date_time)}</CustomDetailTableCell>
                      <CustomDetailTableCell>
                        {request.description.length > 50 ? (
                          <Tooltip 
                            title={request.description}
                            arrow
                            placement="top"
                            data-test-id="tooltip"
                          >
                            <Box display="flex" alignItems="center">
                              {`${request.description.substring(0, 50)}... `}
                              <InfoIcon sx={{ fontSize: "16px", marginLeft: "5px", color: "#A3978F" }} />
                            </Box>
                          </Tooltip>
                        ) : (
                          request.description
                        )}
                      </CustomDetailTableCell>
                      <CustomDetailTableCell>{request.created_by}</CustomDetailTableCell>
                      <TableCell>
                        <StatusCell status={request.status}>
                          {request?.status?.toUpperCase()}
                        </StatusCell>
                      </TableCell>
                      <CustomDetailTableCell>{request.availability}</CustomDetailTableCell>
                      <TableCell>
                      {request.status?.toUpperCase() === "PENDING" && (
                          <IconButton  data-test-id="checkButton" onClick={() => this.handleCheckRequest(request)}>
                            <img src={checkButton} alt="Check" style={{ width: '20px', height: '20px' }} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </BorderContainer>
        )}
        </>
      ))
    );
  };
  
  renderTable = (selectedProperties: any, properties: any, selectedUnits: any) => {
    return (
      (properties)
        .filter((property: any) =>
          property.units.some((unit: any) => unit.tenant_service_requests && unit.tenant_service_requests.length > 0)
        )
        .map((property: any) => (
          <Box  
          data-test-id="table-unit"
          key={property.property_id}>
            {this.renderUnit(selectedProperties, property, selectedUnits)}
          </Box>
        ))
    );
  };
    renderFilter = () => (
      <Box sx={{ display: 'flex', flexWrap: "wrap", alignItems: 'center', gap: "1rem" ,marginTop:"1rem",marginBottom:"1rem"}} data-test-id="chip-filter-box" >
        {this.state.chips.map((chip, chipIndex) => (
        <Chip
          key={chipIndex}
          deleteIcon={<img src={deleteIcon} alt="delete icon" style={{ width: '24px', height: '24px' }} />}
          label={`${chip.propertyName} | ${chip.unitName}`}
          data-test-id="properties-chip"
          sx={{
            fontSize: '12px',
            padding: '1px 6px 1px 6px',
            color: '#4A3F35',
            borderRadius: '4px',
            display: "flex",
            border: '1px solid #000',
            flexDirection: 'row-reverse',
            textTransform: 'uppercase',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
            fontFamily: "Outfit",
            fontWeight: '700',
            '.MuiChip-deleteIcon': {
              color: '#4A3F35',
              marginLeft: '8px',
            },
          }}
          onDelete={() => this.removeChip(chipIndex)}
        />
          ))}

         {this.state.selectedCategoryNames.map((selectedId:any) => {
                                 const category = this.state.allCatagory.find((cat) => cat.attributes.id === selectedId)
                                   if (category) {
                                  return (
                                          <Chip
                                            key={selectedId}
                                            data-test-id="chip-category"
                                            label={category.attributes.name}  
                                            onDelete={() => this.handleDeleteCategory(selectedId)}
                                            deleteIcon={<img src={deleteIcon} style={{  height: '24px',width: '24px'}} />}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              padding: '1px 6px 1px 6px',
                                              borderRadius: '4px',
                                              fontSize: '12px',
                                              fontFamily: 'Outfit',
                                              border: '1px solid #000',
                                              flexDirection: 'row-reverse',
                                              fontWeight: '700',
                                              color: '#4A3F35',
                                              backgroundColor: 'white',
                                              textTransform: 'uppercase',
                                              '.MuiChip-deleteIcon': {
                                                marginLeft: '8px',
                                                color: '#4A3F35',
                                              },
                                            }}
                                          />
                                        );
                                      }
                                      return null;
                                              })}
         {this.state.selectedStatus.map((option) => (
                                  <Chip
                                    data-test-id="chip-status"
                                    key={option}
                                    label={option}
                                    onDelete={() => this.handleDeleteDescription(option)}
                                    deleteIcon={<img src={deleteIcon} alt="delete icon" style={{ width: '24px', height: '24px' }} />}
                                    sx={{
                                      display: "flex",
                                      color: '#4A3F35', 
                                      justifyContent: 'space-between',
                                      textTransform: 'uppercase' ,
                                      padding: '1px 6px 1px 6px', 
                                      border: '1px solid #000', 
                                      backgroundColor: 'white', 
                                      borderRadius: '4px',
                                      fontFamily:"Outfit",
                                      flexDirection: 'row-reverse',
                                      fontWeight: '700', 
                                      alignItems: 'center',
                                      fontSize: '12px', 
                                      '.MuiChip-deleteIcon': {
                                        marginLeft: '8px', 
                                        color: '#4A3F35', 
                                      },
                                    }}
                                  />
          ))}
      </Box>
    )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <VisualAnalyticsStyle>
        <NavAroundArea>
        <NavigationSidebar
          activeItem={this.state.activeMainItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.serviceOrder, labelKey: configJSON.serviceOrderlabel, icon:Service, activeIcon:Service , pathName: configJSON.serviceOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.documentLable, labelKey: configJSON.documentPath, icon: documents, activeIcon: documentActive, pathName: configJSON.documentPath },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
          ]}
          data-test-id={"navId"}
          openDrawer={this.state.openLandlordDrawer}
          onClickDrawer={this.handleDrawerClick}
          keasylogo={keasyIcon}
          onClickSidebar={this.handleNavClick}
        />
        </NavAroundArea>
        <ServiceArea display={"flex"} width={"100%"} justifyContent={"space-between"}  flexDirection={"column"}
         >
        <MainDashboardContainer>
        <LandlordServiceDashboardStyle>
          <CustomNavbar 
              {...this.screenProps}
              showChatButton={true}   
              showPropertiesButton={true} 
            />
          <WorkOrderHead data-test-id="seeTextId">
            Service Requests
          </WorkOrderHead>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
               
              </Grid>
              <Grid item xs={12}>
              <Box className="filter-content">
                  <Box style={{display: "flex"}}>
                    <Box className="filter">
                      <img
                        className="icon"
                        src={filterIcon} alt="filter" />
                      <Typography className="filter-data" data-test-id="headClickId">{configJSON.filtersHead}</Typography>
                    </Box>
                    <FilterWeb>
                      {this.renderFilter()}
                    </FilterWeb>
                  </Box>
                  <Typography className="clear-data" data-test-id="clearOrderId" onClick={this.clearChips}>{configJSON.clearFilters}</Typography>
                  <Box sx={{
                    display:"flex",
                    gap:"1rem"
                  }}>
                    <Button data-test-id="propertyClickId" className="properties-dropdown"  onClick={this.handleProperClick} >
                      <Typography data-test-id="propertyNameId" className="text-data">{configJSON.propertiesHead}</Typography>
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Select
                    data-test-id="Category-dropdown"
                    value={this.state.selectedCategoryNames}
                    multiple
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(event) => {
                      const { target: { value } } = event;
                      this.setState({
                        selectedCategoryNames: typeof value === 'string' ? value.split(',') : value,
                      }, () => {
                        this.applyAllFilters();
                      });
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      return <CustomLableCell>Category</CustomLableCell>;
                    }
                  }
                    sx={{
                      borderRadius: '8px',
                      fontFamily: 'Outfit',
                      boxShadow: 'none',
                      border: 'none',
                      height: '2.7rem',
                      backgroundColor: '#FFF1CC',
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000',
                        fontFamily: 'Outfit',

                      },
                      '& label': {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#000',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#000',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                          '& .MuiMenuItem-root': {
                            minHeight: 'auto',
                            fontFamily: 'Outfit',

                          },
                        },
                      },
                    }}
                    >
                      {this.state.allCatagory.map((cat) => (
                        <MenuItem key={cat.attributes.id} value={cat.attributes.id}>
                          {cat.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      data-test-id="status-dropdown"
                      IconComponent={KeyboardArrowDownIcon}
                      multiple
                      value={this.state.selectedStatus}
                      onChange={(event) => {
                        this.setState({ selectedStatus: event.target.value as string[]}, () => {
                          this.applyAllFilters();
                        });
                      }}
                      displayEmpty
                      renderValue={(selected) => {
                    return <CustomLableCell>Status</CustomLableCell>;
                      }}
                      sx={{
                        boxShadow: 'none',
                        fontFamily: 'Outfit',
                        border: 'none',
                        backgroundColor: '#FFF1CC',
                        borderRadius: '8px',
                        height: "2.7rem",
                        '& .MuiSvgIcon-root': {
                          color: '#000',
                        },
                        '& .MuiSelect-select': {
                          display: 'flex',
                          alignItems: 'center',
                          fontFamily: 'Outfit',
                        },
                        '& label': {
                          fontSize: '16px',
                          fontWeight: 'bold',
                          color: '#000',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .MuiMenuItem-root': {
                              fontFamily: 'Outfit',
                              minHeight: 'auto',
                            },
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      {configJSON.descriptionOptions.map((option:any) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                 
                </Box>
              
              </Grid>
              <Grid item md={6} xs={12}>
                
              </Grid>
            </Grid>
            {this.state.properties.length > 0 ? this.renderTable(this.state.selectedProperties,this.state.properties,this.state.selectedUnits) : <Typography style={{marginTop: "30px", textAlign: "center",fontFamily: "Outfit"}}>No record found</Typography>}
            
          </LandlordServiceDashboardStyle>
            </MainDashboardContainer>
            <Box style={{display: "flex",justifyContent: "flex-end",marginTop: "2.5rem",}}>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
            </Box>
          </ServiceArea>
          <Menu
          data-test-id="menuId"
          id="property-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          slotProps={{
            paper: {
              style: { width: "200px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginLeft: "-2rem", marginTop: "0.5rem" }
            }
          }}
        >
          {this.handlePropertyData().map((property: PropertiesDropdown, propIndex: number) => (
            <CustomMenuItem
              data-test-id="propertyMenuId"
              key={property.attributes.property_name}
              onClick={(propevent) => this.handlePropertyClick(propevent, property, propIndex)}
              className={this.handleChangeColor(propIndex)}
            >
              <CustomListItemText primaryTypographyProps={{ fontSize: "14px", whiteSpace: "nowrap",
              overflow: "hidden", textOverflow: "ellipsis", width: "135px" }} primary={property.attributes.property_name} />
              <KeyboardArrowRightIcon />
            </CustomMenuItem>
          ))}

          {this.handlePropertySlice() && (
            <CustomMenuItem
              data-test-id="seeAllId"
              key="seeAll"
              onClick={this.toggleShowAll}
            >
              <CustomListColor primaryTypographyProps={{ fontSize: "14px" }} primary="See all" />
            </CustomMenuItem>
          )}
        </Menu>
        {this.state.selectedProperty && (
          <Menu
            data-test-id="menuItemId"
            anchorEl={this.state.subMenuAnchorEl}
            keepMounted
            open={Boolean(this.state.subMenuAnchorEl)}
            onClose={this.handleCloseMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            slotProps={{
              paper: {
                style: { width: "120px", marginLeft: "10px" }
              }
            }}
          >
            {this.state.selectedProperty.attributes.units && this.state.selectedProperty.attributes.units.length > 0 && (
              this.state.selectedProperty.attributes.units.map((unit: any) => (
                <UnitMenuItem
                  data-test-id="unitMenuId"
                  key={unit.id}
                  onClick={() => this.handleUnitClick(unit.unit_name, unit.id)}
                >
                  <CustomListItemText primaryTypographyProps={{
                    fontSize: "14px", whiteSpace: "nowrap",
                    overflow: "hidden", width: "100px", textOverflow: "ellipsis",
                  }} primary={unit.unit_name} />
                </UnitMenuItem>
              ))
            )}
          </Menu>
        )}
        <React.Fragment>
      </React.Fragment>
      <React.Fragment>
      </React.Fragment>
      </VisualAnalyticsStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LandlordServiceDashboardStyle = styled(Box)({
  width: "90%",
  fontFamily: "Outfit",
  "& .properties-dropdown": {
    width: "142px",
    fontFamily: "Outfit",
    height: "44px",
    display: "flex",
    padding: "2px 10px 2px 10px",
    alignItems: "center",
    textTransform: "none",
    backgroundColor: "rgb(253,242,208)",
    color: "#000000",
    cursor: "pointer",
    borderRadius: "8px",
    justifyContent: "space-around",
    "&:hover": {
      color: "#000000",
      backgroundColor: "rgb(253,242,208)",
    },
    "@media(max-width: 420px)": {
      width: "105px",
      fontSize: "12px",
      height: "30px"
    }
  },
  
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    height: "16px",
    width: "16px",
  },
  "& .text-data": {
    fontWeight: 700,
    fontFamily: "Outfit",
    fontSize: "16px",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },

  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    fontFamily: "Outfit",
    "@media(max-width: 992px)": {
    paddingRight: "0px",
    }
  },
  "& .filter": {
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontWeight: 700,
    fontSize: "12px",
    fontFamily: "Outfit",
    color: "#000000",
    paddingRight: "10px",
    "@media(max-width: 992px)": {
      paddingRight: "0px"
    }
  },
  "& .isUnitsDisabled": {
    display: "none"
  },
  "& .clear-data": {
    fontSize: "12px",
    marginRight:"10px",
    fontWeight: 700,
    fontFamily: "Outfit",
    whiteSpace: "nowrap",
    color: "#CC9200",
    cursor: "pointer",
    paddingLeft: "10px",
    "@media(max-width: 992px)": {
      paddingLeft: "0px"
    }
  },
});
const MainDashboardContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});


const GridItem = styled(Grid)({
  width: "100%"
});


const getStatusStyles = (status: string) => {
  switch (status) {
    case "pending":
      return {
        color: "#D97706",
        backgroundColor: "#FEF3C7",
      };
    case "approved":
      return {
        color: "#059669",
        backgroundColor: "#D1FAE5",
      };
    case "rejected":
      return {
        color: "#DC2626",
        backgroundColor: "#FEE2E2",
      };
    default:
      return {
        color: "#FFA500",
        backgroundColor: "#FFF3E0",
      };
  }
};
const StatusCell = styled(TableCell)<{ status: string }>(({ status }) => ({
  fontFamily: "Outfit",
  width: "100%",
  fontWeight: 700,
  fontSize: "12px",
  padding: "2px 8px",
  borderRadius: "40px",
  textAlign: "center",
  borderBottom: "none",
  ...getStatusStyles(status),
}));
const CustomDetailTableCell = styled(TableCell)({
  fontSize: "12px",
  color: "#1C1917",
  fontWeight: 400,
  width: '14.28%',
  textAlign: 'center',
  fontFamily: "Outfit"
});


const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem 3rem 1rem 3rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "@media(max-width: 475px)": {
    padding: "1rem",
  },
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#000000",
  margin: "0px 5px 0px 5px",
  "@media(max-width: 475px)": {
    fontSize: "10px",
  },
  "&.changeColor": {
    backgroundColor: "#f3f2f0",
    borderRadius: "8px"
  }
});

const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily: "Outfit",
});
const CustomListColor = styled(ListItemText)({
  color: "#CC9200",
  fontSize: "14px",
  fontWeight: 400
});

const HeadName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});


const HeadItems = styled(Box)({
  display: "flex",
  gap: "10px"
});



const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});
const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomListItemText = styled(ListItemText)({
  fontSize: "14px",
  fontWeight: 400,
  "@media(max-width: 475px)": {
    width: "12px"
  },
  "& .MuiTypography-root": {
    fontFamily: "Outfit",
    fontSize: "14px",
  }
});
const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  width: '14.28%',
  fontFamily: "Outfit",
  borderBottom:"none",
});
const ServiceArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const CustomLableCell = styled(Typography)({
  alignContent:"center",
  color: "#0F172A",
  fontSize: "16px",
  fontWeight: 700,
  fontFamily:"Outfit",
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const UnitMenuItem = styled(MenuItem)({
  fontWeight: 400,
  fontFamily: "Outfit",
  fontSize: "14px",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});
const VisualAnalyticsStyle = styled(Box)({
  display: "flex",
  fontFamily: "Outfit, sans-serif",
  width: "100%",
  justifyContent: "space-between",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "& .isTanentDisabled": {
    display: "none"
  },

  "& .filter-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    marginRight: "1rem",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },

  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
})
const FilterWeb = styled(Box)({
  maxWidth:"80%",
  fontFamily: "Outfit",
  paddingRight: "20px",
  "@media(max-width: 992px)": {
    display: "none"
  }
});
const NavAroundArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});

// Customizable Area End
