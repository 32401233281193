import React from "react";

import {
    
    Box,
    Grid,
    Button,
    Paper,
    Typography,
    styled,

    // Customizable Area Start



    // Customizable Area End
    
} from "@mui/material";

// Customizable Area Start

import CustomTimePicker from "../../../components/src/CustomTimePicker.web"
import {
    bell, 
    dashboard_g,
    Key_logo,
    propertyMain, 
    activePropertyMain, 
    financial, 
    activeOrderFinancial,
    activeDashboard,
    activeBell,
    activeKey,
    chatKey,
} from "./assets";
import {
    createTheme,
    ThemeProvider,

} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";

import CustomDatePicker from "../../../components/src/CustomDatePicker.web";

import { Formik } from 'formik';
import * as Yup from 'yup';
import PopupMessage from "../../../components/src/PopupMessage.web";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";



const validationSchemas = Yup.object().shape({
    category: Yup.number().required('Category is required'),
    workNeeded: Yup.string().required('Work needed is required'),
    dueDate: Yup.date().required('Due date is required').nullable(),
    dueHour: Yup.string().required('Due time is required'),
    dueDateFrequency: Yup.string().required('Select frequency'),
    remindMeOnDate: Yup.date().required('Reminder date is required').nullable(),
    remindMeOnHour: Yup.string().required('Reminder time is required'),
    remindMeFrequency: Yup.string().required('Select frequency'),
    availabilityState: Yup.string().required('Permission description is required'),
    description: Yup.string().required('Description is required')
});
const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import AutomatedworkflowController, {
    Props,
    configJSON,

} from "./AutomatedworkflowController";

export default class Automatedworkflow extends AutomatedworkflowController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    screenProps = {
        navigation: this.props.navigation,
        id: "Analytics",
      }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                 
                     <AddWorkOrderStyle>
                        <NavArea>
                            <NavigationSidebar
                            data-test-id={"navId"}
                            activeItem={this.state.activeMainNewrequest}
                            sidebarItems={[
                                { label: configJSON.dashboardLable, labelKey: configJSON.dashboardPath, icon: dashboard_g, activeIcon: activeDashboard, pathName: configJSON.dashboardPath },
                                { label: configJSON.serviderequest, labelKey: configJSON.serviderequestPath, icon: bell, activeIcon: activeBell, pathName: configJSON.serviderequestPath },
                                { label: configJSON.tenentlable, labelKey: configJSON.tenentBlock, icon:propertyMain , activeIcon: activePropertyMain, pathName: configJSON.tenentBlock },
                                { label: configJSON.paymentLable, labelKey: configJSON.paymentBlock, icon:financial , activeIcon: activeOrderFinancial, pathName: configJSON.paymentBlock },
                                { label: configJSON.chatLable, labelKey: configJSON.chatBlocK, icon:chatKey , activeIcon: activeKey, pathName: configJSON.chatBlocK }
                            ]}
                            onClickSidebar={this.handleMainItemClicknewrequest}
                            keasylogo={Key_logo}
                            openDrawer={this.state.openTenentDrawers}
                            onClickDrawer={this.handleTenentrequestDrawers} />
                        </NavArea>
                            <PopupMessage
                            open={this.state.messageOpen}
                            type={this.state.messageType}
                            message={this.state.message}
                            handleClose={this.handlePopupMessageClose}
                            />
                            <Box sx={{width:"85%",marginLeft:"10rem"}}>
                            <CustomNavbar 
                                {...this.screenProps}
                                showChatButton={true}   
                                showPropertiesButton={false} 
                            />
                            </Box>
                        <Box className="rightBoxs">
                            <Box className="headingreq" style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box>New Request</Box>
                                <Button 
                                sx={{
                                    width: "80px",
                                    height: "32px",
                                    fontWeight: 700,
                                    backgroundColor: "#FFC123",
                                    borderRadius: "8px",
                                    color: "#000000", 
                                    fontSize: "14px",
                                    padding: "0px",
                                    letterSpacing: 0,
                                    cursor: "pointer",
                                    fontFamily: "Outfit, sans-serif",
                                    "&:hover": {
                                    backgroundColor: "#FFD700", 
                                    },
                               }} style={{ textTransform: 'none' }} data-test-id="backBtnTest" onClick={this.navigateToAppointments}>Go Back</Button>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item md={9} sm={12} xs={12}>
                                
                                    <Box className="workOrderFormreq">
                                        <Formik
                                            enableReinitialize={true}
                                            validateOnBlur={true}
                                            validateOnChange={false}
                                            initialValues={{
                                                category: '',
                                                workNeeded: '',
                                                dueDate: null,
                                                dueHour: '',
                                                dueDateFrequency: '',
                                                remindMeOnDate: null,
                                                remindMeOnHour: '',
                                                remindMeFrequency: '',
                                                availabilityState: '',
                                                description: '',
                                            }}                                            
                                            validationSchema={validationSchemas}
                                            onSubmit={async (values, formikHelpers) => {
                                                try {
                                                    await this.addAppointment(values);
                                                } catch (error) {
                                                    alert(error)
                                                }
                                              }}
                                            data-test-id="Formik"
                                        >
                                            {({ errors, values, handleSubmit, setFieldValue }) => {
                                                return (
                                                    <form onSubmit={handleSubmit}>
                                                        <Box className="headingOne">
                                                            <Typography className="headingtwo">{this.state.propertyDetails.property_name} | {this.state.propertyDetails.unit_name}</Typography>
                                                            <Typography className="headingtwo" sx={{
                                                            }}> {this.state.propertyDetails.property_address}</Typography>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Category
                                                            </Box>
                                                            <Box style={{ display: 'block' }} className="InputWrapperRights">
                                                            <select
                                                                className='selectLongsnew'
                                                                onChange={(e) => {
                                                                    setFieldValue("category", e.target.value);
                                                                    this.setCatagory(Number(e.target.value));
                                                                  }}
                                                                data-test-id="categoryTest"
                                                            >
                                                                <option value="">Select a Category</option>
                                                                {this.state.allCatagory && this.state.allCatagory.map((category:any) => (
                                                                    <option key={category.id} value={category.attributes.id}>
                                                                        {category.attributes.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                                {errors.category && (
                                                                    <div className="errorNew">{errors.category}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Work needed
                                                            </Box>
                                                            <Box style={{ display: 'block' }} className="InputWrapperRights">
                                                                <input
                                                                    className='inputNew'
                                                                    onChange={(e) => {
                                                                        setFieldValue("workNeeded", e.target.value);
                                                                        this.setWorkNeeded(e.target.value);
                                                                    }}
                                                                    data-test-id="workNeededTest"
                                                                />
                                                                {errors.workNeeded && (
                                                                    <div className="errorNew">{errors.workNeeded}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Due
                                                            </Box>

                                                            <Box className="InputWrapperRights">
                                                                <Box style={{ marginRight: "25px" }} className="dateSelects">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Date
                                                                    </Typography>
                                                                    <CustomDatePicker
                                                                        value={this.state.dueDate}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("dueDate", newValue);
                                                                            this.handleDateChange(
                                                                                "dueDate", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="dueDateTest"
                                                                    />
                                                                    {errors.dueDate && (
                                                                        <div className="errorNew">{errors.dueDate}</div>
                                                                    )}
                                                                </Box>
                                                                <Box className="dateSelects marginTopnew">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Hour
                                                                    </Typography>
                                                                    <CustomTimePicker
                                                                        value={this.state.dueHour}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("dueHour", newValue);
                                                                            this.handleDateChange(
                                                                                "dueHour", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="dueHourTest"
                                                                    />
                                                                    {errors.dueHour && (
                                                                        <div className="errorNew">{errors.dueHour}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss" style={{ paddingTop: "0px" }}>
                                                            <Box className="InputWrapperLefts">
                                                            </Box>
                                                            <Box className="frequencyWrapper">
                                                                <Box>
                                                                    <select className='frequency' data-test-id="dueFrequencyTest" onChange={(e) => {
                                                                        setFieldValue("dueDateFrequency", e.target.value);
                                                                        this.setDueFrequency(e.target.value);
                                                                        
                                                                    }}>
                                                                        <option>Frequency</option>
                                                                        <option value="daily">Daily</option>
                                                                        <option value="weekly">Weekly</option>
                                                                    </select>
                                                                    {errors.dueDateFrequency && (
                                                                        <div className="errorNew">{errors.dueDateFrequency}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Remind me on
                                                            </Box>
                                                            <Box className="InputWrapperRights">
                                                                <Box style={{ marginRight: "25px" }} className="dateSelects">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Date
                                                                    </Typography>
                                                                    <CustomDatePicker
                                                                        value={this.state.remindMeOnDate}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("remindMeOnDate", newValue);
                                                                            this.handleDateChange(
                                                                                "remindMeOnDate", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="remindMeOnDateTest"
                                                                    />
                                                                    {errors.remindMeOnDate && (
                                                                        <div className="errorNew">{errors.remindMeOnDate}</div>
                                                                    )}
                                                                </Box>
                                                                <Box className="dateSelects marginTopnew">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Hour
                                                                    </Typography>
                                                                    <CustomTimePicker
                                                                        value={this.state.remindMeOnHour}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("remindMeOnHour", newValue);
                                                                            this.handleDateChange(
                                                                                "remindMeOnHour", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="remindMeOnHourTest"
                                                                    />
                                                                    {errors.remindMeOnHour && (
                                                                        <div className="errorNew">{errors.remindMeOnHour}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss" style={{ paddingTop: "0px" }}>
                                                            <Box className="InputWrapperLefts">
                                                            </Box>
                                                            <Box className="frequencyWrapper">
                                                                <Box>
                                                                    <select className='frequency' data-test-id="remindMeOnFrequencyTest" onChange={(e) => {
                                                                        setFieldValue("remindMeFrequency", e.target.value);
                                                                        this.setRemindMeFrequency(e.target.value);
                                                                
                                                                    }}>
                                                                        <option>Frequency</option>
                                                                        <option value="daily">Daily</option>
                                                                        <option value="weekly">Weekly</option>
                                                                    </select>
                                                                    {errors.remindMeFrequency && (
                                                                        <div className="errorNew">{errors.remindMeFrequency}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Availability
                                                            </Box>
                                                            <Box className="radioOuterWrappernew">

                                                                <input className='inputNew' data-test-id="permissionInputTest" onChange={(e) => {
                                                                    setFieldValue("availabilityState", e.target.value);
                                                                    this.setAvailabilityState(e.target.value);
                                                                }} />
                                                                {errors.availabilityState && (
                                                                    <div className="errorNew">{errors.availabilityState}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Description
                                                            </Box>
                                                            <Box style={{ display: 'block' }} className="InputWrapperRights">
                                                                <textarea
                                                                    className='textAreanew'
                                                                    rows={6}
                                                                    onChange={(e) => {
                                                                        setFieldValue("description", e.target.value);
                                                                        this.setDescription(e.target.value);
                                                                    }}
                                                                    style={{
                                                                        font: 'inherit',
                                                                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                                        borderColor: "#bdbdbd",
                                                                        borderRadius: "4px",
                                                                        padding: "8.5px 14px",
                                                                        width: "100%",
                                                                        minHeight: "40px",
                                                                        resize: 'none',
                                                                      }}
                                                                    data-test-id="descriptionTest"
                                                                />
                                                                {errors.description && (
                                                                    <div className="errorNew">{errors.description}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                            </Box>
                                                            <Box className="submitBtnWrappernew">
                                                                <Button className="submitBtnnew" style={{ textTransform: 'none' }} data-test-id="btnSubmit" type="submit"> Request Service</Button>
                                                            </Box>
                                                        </Box>
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </Box>
                                     
                                </Grid>
                                <Grid item md={3} sm={12} xs={12}>
                                    <Box style={{ border: "1px solid #FFE299", height: "95%", borderRadius: '8px 8px 32px 8px', marginTop: "2rem" }}>
                                        <Box sx={{ padding: "1rem" }}>
                                            <Box>
                                                <img src={Key_logo} alt="wait_BC_FOR__GUY" />
                                            </Box>
                                            <Box sx={{marginTop:"1rem"}}>
                                                <Typography sx={{fontFamily:"Outfit",color:"#000000",fontSize:"14px",lineHeight:"22px"}}>
                                                If you have urgent maintenance requests (such as issue with running water, power outage, or lack of heating in winter) please contact our 24/7 maintenance line at 123-456-789
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="headingtwos">For emergency, dial 911</Typography>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                             <Box className="backgroundImgWrapper">
                                            <Box className="backgroundImg">
                                            <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
                                            </Box>
                                            <Box className="backgroundImg">
                                            <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
                                            </Box>
                                            <Box className="backgroundImg">
                                            <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
                                            </Box>
                                            <Box className="backgroundImg">
                                            <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
                                            </Box>
                             </Box>
                        </Box>
                    </AddWorkOrderStyle>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const AddWorkOrderStyle = styled(Box)({
    display:"flex",
    flexDirection:"column",
    "& .headingOne": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "2rem",
        padding: "2rem",
        alignItems: "center",
    },
    "& .headingtwo": {
        fontSize: "16px",
        fontFamily:"Outfit",
        fontWeight: "700",
        lineHeight: "24px",
        fontfamily: "outfit"
    },
   
  
    "& .InputWrapperss": {
        display: "flex",
        padding: "2rem 0rem 2rem 2rem",
        "@media (max-width:600px)": {
            display: "block",
            padding: "1rem 0rem 1rem 0rem",
        }
    },
   
  
    "& .selectLongsnew": {
        width: "670px",
        height: "44px",
        border: "1px solid #BAB1AB",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        paddingLeft: "10px",
        "@media (max-width:1200px)": {
            width: "100%",
        }
    },
   
  
    "& .InputWrapperLefts": {
        width: "20%",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "17px",
        color: "#BAB1AB",
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    "& .workOrderFormreq": {
        width: "90%",
        border: "1px solid #FFE299",
        borderRadius: "8px",
        margin: "2rem",
        padding: "2rem",
        "@media (max-width:586px)": {
            width: "100",
            padding: "0rem",
        },
        "@media (max-width:1200px)": {
            width: "95%",
            padding: "0rem"

        },
        "@media (max-width:1000px)": {
            margin: "0.5rem"
        },

        "@media (max-width:600px)": {
            width: "100%",
            border: "none"
        }
    },
    "& .InputWrapperRights": {
        display: "flex",
        width: "80%",
        marginRight: "25px",
        "@media (max-width:600px)": {
            width: "100%",
            display: "block",
            marginTop: "1rem",
        }
    },
    "& .headingreq": {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '32px',
        marginLeft: "2rem",
        marginTop: "2rem",

        "@media (max-width:1000px)": {
            marginLeft: "0.5rem",
        },
    },
   
    "& .inputNew": {
        width: "670px",
        height: "44px",
        border: "1px solid #BAB1AB",
        borderRadius: "8px",
        paddingLeft: "10px",
        "@media (max-width:1200px)": {
            width: "100%",
        }
    },
   
    "& .radioOuterWrappernew": {
        width: "80%",
        marginRight: "25px",
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    fontFamily: "Outfit, sans-serif",
    width: "100%",
    "& .rightBoxs": {
        width: "87%",
        overflow: "hidden",
        backroundColor:"red",
        marginLeft:"8rem",
        "@media (max-width:486px)": {
            paddingRight: "1.3rem",
        },
    },
    "& .textAreanew": {
        width: "670px",
        border: "1px solid #BAB1AB",
        borderRadius: "8px",
        padding: "10px",
        marginRight: "25px",
        "@media (max-width:1000px)": {
            width: "100%",
        }
    },
 
  
    "& .submitBtnWrappernew": {
        width: "670px",
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        "@media (max-width:600px)": {
            width: "100%",
            justifyContent: "flex-start",
        }
    },
    "& .frequencyWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        width: "670px",
        "@media (max-width:600px)": {
          width: "100%",
        }
      },
      "& .frequency": {
        width: "100px",
        height: "30px",
        border: "none",
        borderRadius: "8px",
        paddingLeft: "10px",
        backgroundColor: "#FFF1CC",
        "@media (max-width:1000px)": {
          marginRight: "25px",
        }
      },
    "& .dateSelects": {

        width: "322px",
        "@media (max-width:1200px)": {
            width: "48%",
        },
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    "& .marginTopnew": {
        "@media (max-width:600px)": {
            marginTop: "1rem",
        }
    },

    "& .errorNew": {
        color: "#ff0000",
        fontSize: "12px",
        marginTop: '3px',
    },
    "& .submitBtnnew": {
        width: "175px",
        height: "44px",
        backgroundColor: "#FFC123",
        borderRadius: "8px",
        color: "#000000",
        marginRight: "25px",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: "Outfit, sans-serif",
        "&:hover": {
            backgroundColor: "#FFD700",
        },
        "@media (max-width:600px)": {
            marginRight: "0px",
        }
    },
    "& .headingtwos": {
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        marginTop: "1rem",
        fontFamily:"Outfit"

    },
    "& .backgroundImgWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "2.5rem",
        "@media (max-width:600px)": {
          display: "none",
        }
      },
      "& .backgroundImg": {
        width: "250px",
      },
});
const NavArea = styled(Box)({
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000, 
    border: "1px solid rgb(208,203,199)",
    "&::-webkit-scrollbar": {
        display: "none"
      },
      "scrollbar-width": "none",
      "-ms-overflow-style": "none",
    "@media(max-width: 992px)": {
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    }
  });
// Customizable Area End