Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.appointmentApiContentType = "application/json";

exports.appointmentAPiEndPoint = "account_block/landlord_work_orders";

exports.addAppointmentAPiMethod = "POST";
exports.deleteAllAppointmentsAPiMethod = "DELETE";
exports.getAppointmentListAPiMethod = "GET";

exports.format = "DD/MM/YY";
exports.serverFormat = "YY/MM/DD";
exports.webFormat = "dd/MM/yyyy";
exports.timeFormat = "HH:mm A";
exports.minDate = "01/01/20";
exports.maxDate = "01/01/25";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.availableDate = "Available Date";
exports.availableFrom = "Available From";
exports.availableTo = "Available To";
exports.viewAllAppointments = "View all Appointments";
exports.addAppointment = "Add Appointment";
exports.confirm = "Confirm";
exports.cancel = "Cancel";
exports.datePickerPlaceholder = "Select Date";
exports.datePickerPlaceholderFormat = "DD/MM/YY";
exports.timePickerPlaceholder = "Select Time";
exports.getList = "Get List";
exports.slot = "Slot";
exports.getPropertiesAcceptanceApiEndPoint = "account_block/properties";

exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.serviceOrder = "Service Requests";
exports.propertiesHead = "Properties";
exports.newWorkOrders = "New work order";
exports.serviceOrderlabel = "LandlordServiceRequestsDashboard";
exports.filtersHead = "Filters";
exports.clearFilters = "Clear all";
exports.headNames = "12431 BLV";
exports.headMainContent = "144 39th st. Bellevue WA 98005";
exports.viewCompleted = "View completed";
exports.viewScheduled = "View scheduled";
exports.scheduledDate = "Scheduled date";
exports.waitingBill = "Waiting for bill";
exports.approve = "Approve";
exports.findKeys = "Find keys";
exports.selectedOrderName = "orders selected";
exports.occupiedName = "OCCUPIED";
exports.unitsName = "Unit 1";
exports.selectedCount = "No";

exports.idName = "ID";
exports.createdName = "Created by";
exports.categoryName = "Category";
exports.SelectedVendor = "Selected vendor";
exports.workNeedName = "Work needed";
exports.assignVendorName = "Assigned vendor";
exports.estimatedCostName = "Estimated cost";
exports.statusName = "Current status";
exports.descriptionName = "Description";
exports.fileAdded = "File added";
exports.goingOnName = "What's going on";

exports.dateName = "Date";
exports.updatedName = "Updated by";
exports.detailsName = "Details";
exports.status = "Status";
exports.getOpenWorkApiEndPoint = "account_block/landlord_work_orders/";
exports.costName = "Cost";
exports.statusChangeName = "Status Change";
exports.imagesUpload = "Images";
exports.completed = "Complete";
exports.transfer = "Transfer to another vendor";
exports.payBill = "Pay bill";
exports.putWorkOrderApiEndPoint = "account_block/landlord_work_orders/";
exports.assignVendorNew = "Assign a Vendor";
exports.submitUpdate = "Submit Update";
exports.orderOpen = "Open work order";
exports.editWork = "Edit work order";
exports.decline = "Decline work order";
exports.workOrderApiEndPoint = "account_block/landlord_work_orders";
exports.propertiesApiEndPoint = "account_block/properties";
exports.openedName = "Opened";
exports.hashTagName = "#";
exports.estimatedCost = "";
exports.workOrderMissing = "Work order not found";
exports.noImages = "No Images";
exports.preview = "Preview";
exports.lowCost = "";
exports.payBill = "Pay bill";
exports.workOrders = "Work Order";
exports.backButton = "Back";
exports.updateWork = "Work Order Updates";
exports.assignVendor = "Transfer to other vendor";
exports.completedWork = "Complete work order";
exports.declinedWork = "Decline work order";
exports.orderId = "WORK ORDER #";
exports.auditMissing = "No record found";
exports.postApprovedApiEndPoint = "account_block/landlord_work_orders/approved_by_landlord";
exports.errorMessage = "error";
exports.postWaitingApiEndPoint = "account_block/landlord_work_orders/waiting_for_bill";
exports.keyLocatedMessage = "Keys located in the mailbox, enter code #";
exports.doorClose = ". Keep door closed at all times.";
exports.keyLocation = "Keys Location";
exports.floorName = "Floor:";
exports.entrance = "Entrance:";
exports.codeName = "Code #";
exports.postScheduledApiEndPoint = "account_block/landlord_work_orders/scheduled_date";
exports.putVendorApiEndPoint = "account_block/landlord_work_orders/";
exports.putAppointmentListAPiMethod = "PUT";
exports.noProperty = "Property not found, Please create a property.";
exports.filteredMessage = "This property is already filtered.";
exports.naTextValue = "NA";
exports.showVendorApiEndPoint = "account_block/vendor_work_orders/";
exports.searchsError = "Search box must not exceed 30 characters.";
exports.getShowVendorsApiEndPoint = "account_block/landlord_accounts/show_all_preferred_vendors";
exports.selectVendor = "Select a vendor";
exports.preferredVendors = "Preferred Vendors";
exports.documentPath= "Cfdocumentforwarding4";
exports.docuLandlordLabel = "Documents";
exports.readLess = "Read Less";
exports.documentLable = "Documents";
exports.readMore = "Read More";
exports.goBackButton = "Go Back";
exports.noFound = "Files not found";
exports.mbFile = "MB";
exports.documentlabel = "Cfdocumentforwarding4";
exports.getVendorsAcceptanceApiEndPoint = "account_block/landlord_accounts/show_all_preferred_vendors";
exports.getCatagoryAcceptanceApiEndPoint = "bx_block_categories/categories";
exports.getServiceRequest="account_block/tenant_service_requests/landlord_tenant_service_requests";
exports.getInfoServiceRequest="account_block/tenant_service_requests";
exports.descriptionOptions = ["pending", "rejected", "approved"];
exports.getCatagorApiEndPoint="bx_block_categories/sub_categories?slug_name=vendor-types";

// Customizable Area End
