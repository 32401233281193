import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
import { SelectChangeEvent } from '@mui/material/Select';
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider";

interface PaginationState {
    currentPage: number;
    totalPages: number;
  }

  export interface FileWithPreview extends File {
  preview: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface Countries {
  data: Array<AreasCode>;
}

export interface AreasCode {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}

export interface Category {
  data: Array<CatType>;
}

export interface CatType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    sub_categories: Array<SubCategory>;
  }
}

export interface SubCategory {
  id: number;
  name: string;
}
  
  
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    itemsPerPage: number;
    totalItems: number;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    filesWeb: File[];
    files: any[];
    uploadedFiles: ""
    filesStatus: (undefined | "uploading" | "success" | "failed")[];
    activeStepedit:number;
    
    
    activeMainItemsedit: string;
    openLandlordDrawersedit: boolean
    activeStep: number;
    vendorType: string;
    ownerName: string;
    vendorTrade: string[];
    taxpayerName: string;
    taxpayerID: string;
    taxFormAccountNumber: string;
    send1099: string;
    workersCompExpiration: Date | null;
    generalLiabilityExpiration:  Date | null;
    epaCertificationExpiration:  Date | null;
    autoInsuranceExpiration: Date | null;
    stateLicenseExpiration: Date | null;
    contractExpiration: Date | null;
    phoneNumber: string;
    email: string;
    address: string;
    portalActivated: string;
    areaCode: string;
    taxpayerId: string;
    useOnlinePayables: string;
    paymentType: string;
    vendorBankRoutingNumber: string;
    vendorBankAccountNumber: string;
    accountType: string;
    expirationDate1: Date | null;
    expirationDate2: Date | null;
    notes: string;
    filesDocuments: FileWithPreview[];
    filesName: string;
    filesAccountType: FileWithPreview[];
    filesInsurance: FileWithPreview[];
    licenceInsurance: FileWithPreview[];
    openSnack: boolean;
    errorMessage: string;
    vendorsItem: Array<CatType>;
    payments: string[];
    errors: {
      vendorTrade: boolean;
      ownerName: boolean;
      monFriTime: boolean;
      satTime: boolean;
      sunTime: boolean;
      phone: boolean;
      email: boolean;
      formW9Files: boolean;
      bankRoutingNumber: boolean;
      bankAccountNumber: boolean;
      businessLicense: boolean;
      licenseExpDate: boolean;
      businessInsurance: boolean;
      insuranceExpDate: boolean;
      taxpayerName: boolean;
      taxpayerId: boolean;
      websiteLink: boolean;
      companyName: boolean;
      notes: boolean;
    };
    trades: string;
    isCountryDropdown: boolean;
    countriesCode: Array<{ name: string; emoji_flag: string; country_code: string }>;
    companyName: string;
    businessLicense: FileWithPreview[];
    licenseExpDate: Date | null;
    businessInsurance: FileWithPreview[];
    stateLicenseExpDate: Date | null;
    insuranceExpDate: Date | null;
    epaCertificationLicense: FileWithPreview[];
    stateLicense: FileWithPreview[];
    epaCerLicenseExpDate: Date | null;
    otherCertificate: FileWithPreview[];
    otherCertificateExpDate: Date | null;
    stateInsurance: FileWithPreview[];
    stateInsuranceExpDate: Date | null;
    venMessage: 'success' | 'error' | 'warning' | 'info';
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}
export default class TenantDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAreaCodeApiCallId: string = "";
    postAddVendorApiCallId: string = "";
    getVendorTypeApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            token: "",
            filesWeb: [],
            files: [],
            uploadedFiles:"",
            filesStatus: [],
            activeStepedit: 0,
            activeMainItemsedit: configJSON.prevendorlandlabel,
            openLandlordDrawersedit: false,
            activeStep: 0,
            vendorType: "",
            ownerName: "",
            vendorTrade: [],
            taxpayerName: "",
            taxpayerID: "",
            taxFormAccountNumber: "",
            send1099: 'yes',
            workersCompExpiration: null,
            generalLiabilityExpiration: null,
            epaCertificationExpiration: null,
            autoInsuranceExpiration: null,
            stateLicenseExpiration: null,
            contractExpiration: null,
            phoneNumber: "",
            email: "",
            address: "",
            portalActivated: "yes",
            areaCode: "",
            taxpayerId: "",
            useOnlinePayables: "",
            paymentType: "",
            vendorBankRoutingNumber: "",
            vendorBankAccountNumber: "",
            accountType: "",
            expirationDate1: null,
            expirationDate2: null,
            notes: "",
            filesDocuments: [],
            filesName: "",
            filesAccountType: [],
            filesInsurance: [],
            licenceInsurance: [],
            openSnack: false,
            errorMessage: "",
          vendorsItem: [],
          payments: [
            "eCheck",
            "Pay via check in mail",
            "Pay online via vendor's website",
            "Pay with Venmo",
            "Pay with Zelle"
          ],
          errors: {
            vendorTrade: false,
            ownerName: false,
            monFriTime: false,
            satTime: false,
            sunTime: false,
            phone: false,
            email: false,
            formW9Files: false,
            bankRoutingNumber: false,
            bankAccountNumber: false,
            businessLicense: false,
            licenseExpDate: false,
            businessInsurance: false,
            insuranceExpDate: false,
            taxpayerName: false,
            taxpayerId: false,
            websiteLink: false,
            companyName: false,
            notes: false
          },
          trades: "",
          isCountryDropdown: false,
          countriesCode: [],
          companyName: "",
          otherCertificate: [],
          businessLicense: [],
          epaCertificationLicense: [],
          licenseExpDate: null,
          businessInsurance: [],
          insuranceExpDate: null,
          epaCerLicenseExpDate: null,
          stateInsuranceExpDate: null,
          otherCertificateExpDate: null,
          stateLicense: [],
          stateLicenseExpDate: null,
          stateInsurance: [],
          venMessage: "error"
            // Customizable Area End
        };
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getAreaCode();
        this.getVendorType();
        // Customizable Area End
    }

    // Customizable Area Start

handleNextMainEdit = () => {
  const { email, vendorType, phoneNumber, errors, ownerName, companyName, activeStepedit } = this.state;
    if (activeStepedit === 0) {
      if (!vendorType) {
        this.setState({
          openSnack: true,
          errorMessage: "Please select vendor type",
          venMessage: "error"
        });
        return;
      }
      if (!email) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter email address",
          venMessage: "error"
        });
        return;
      }
      this.validatesPhone(phoneNumber);
      if (phoneNumber && errors.phone) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid phone number",
          venMessage: "error",
        });
        return;
      }
      this.validatesName(ownerName);
      if (ownerName && errors.ownerName) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid owner name",
          venMessage: "error",
        });
        return;
      }
      this.validatesCompanyName(companyName);
      if (companyName && errors.companyName) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid company name",
          venMessage: "error",
        });
        return;
      }

    this.setState((prevState) => ({
    activeStepedit: prevState.activeStepedit + 1
  }));
   return;
  }

  this.handleSecondStep();
};

  handleSecondStep = () => {
    const { taxpayerID, taxpayerName, errors, vendorBankRoutingNumber, vendorBankAccountNumber, activeStepedit } = this.state;

    if (activeStepedit === 1) {
      this.validatesTaxpayerID(taxpayerID);

      if (taxpayerID && errors.taxpayerId) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid taxpayer ID",
          venMessage: "error",
        });
        return;
      }
      this.validatesTaxpayerName(taxpayerName);
      if (taxpayerName && errors.taxpayerName) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid taxpayer name",
          venMessage: "error",
        });
        return;
      }
      this.validatesBankRoutingNumber(vendorBankRoutingNumber);
      if (vendorBankRoutingNumber && errors.bankRoutingNumber) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid bank routing number",
          venMessage: "error",
        });
        return;
      }
      this.validatesBankAccountNumber(vendorBankAccountNumber);
      if (vendorBankAccountNumber && errors.bankAccountNumber) {
        this.setState({
          openSnack: true,
          errorMessage: "Please enter a valid bank account number",
          venMessage: "error",
        });
        return;
      }
      this.setState((prevState) => ({
        activeStepedit: prevState.activeStepedit + 1
      }));
    }
  };

handleBackMainEdit = () => {
  this.setState(prevState => ({
    activeStepedit: prevState.activeStepedit - 1,
    errors: {
      ...prevState.errors,
      ownerName: false,
      phone: false,
      companyName: false
    },
  }));
};

navigateToAppointments = () => {
  this.props.navigation.goBack();
};


handleMainItemClicksEdit = (labelKey: string) => {
  this.setState({ activeMainItemsedit: labelKey },()=>{
    this.handleEditDashboardMenu();
  });
};

handleLandlordDrawersEdit = () => {
  this.setState({
    openLandlordDrawersedit: !this.state.openLandlordDrawersedit
});
};

  handleEditDashboardMenu = () => {
    const { activeMainItemsedit } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItemsedit
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleNext = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  handleBack = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  };

  handleVendorDashboard = () => {
    const toVendorMsg = new Message(getName(MessageEnum.NavigationMessage));
    toVendorMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TaskList"
    );
    toVendorMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toVendorMsg);
  };

  handleDateSubmitting = (formData: FormData) => {
    formData.append("vendor_account[business_insurance_exp_date]", this.state.insuranceExpDate ? this.state.insuranceExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[state_license_exp_date]", this.state.stateLicenseExpDate ? this.state.stateLicenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[other_certificate_exp_date]", this.state.otherCertificateExpDate ? this.state.otherCertificateExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[epa_license_exp_date]", this.state.epaCerLicenseExpDate ? this.state.epaCerLicenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[business_license_exp_date]", this.state.licenseExpDate ? this.state.licenseExpDate.toISOString().split("T")[0] : "");
    formData.append("vendor_account[state_insurance_exp_date]", this.state.stateInsuranceExpDate ? this.state.stateInsuranceExpDate.toISOString().split("T")[0] : "");
  };

  handleFilesSubmitting = (formData: FormData) => {
    if (this.state.businessInsurance.length) {
      this.state.businessInsurance.forEach((file) => {
        formData.append("vendor_account[business_insurances][]", file as Blob);
      });
    }
    if (this.state.epaCertificationLicense.length) {
      this.state.epaCertificationLicense.forEach((file) => {
        formData.append("vendor_account[epa_certification_licenses][]", file as Blob);
      });
    } if (this.state.businessLicense.length) {
      this.state.businessLicense.forEach((file) => {
        formData.append("vendor_account[business_licenses][]", file as Blob);
      });
    }
    if (this.state.stateLicense.length) {
      this.state.stateLicense.forEach((file) => {
        formData.append("vendor_account[state_licenses][]", file as Blob);
      });
    }
    if (this.state.stateInsurance.length) {
      this.state.stateInsurance.forEach((file) => {
        formData.append("vendor_account[state_insurances][]", file as Blob);
      });
    }
    if (this.state.otherCertificate.length) {
      this.state.otherCertificate.forEach((file) => {
        formData.append("vendor_account[epa_other_certificates][]", file as Blob);
      });
    }
    if (this.state.filesDocuments.length) {
      this.state.otherCertificate.forEach((file) => {
        formData.append("vendor_account[form_w9][]", file);
      });
    }
  };

  handleSubmit = () => {
    const formData = new FormData();
    formData.append("vendor_account[company_name]", this.state.companyName);
    formData.append("vendor_account[vendor_type]", this.state.vendorType);
    formData.append("vendor_account[name]", this.state.ownerName);
    for (const trades of this.state.vendorTrade) {
      formData.append("vendor_account[vendor_trade][]", trades);
    }
    formData.append("vendor_account[phone_number]", this.state.areaCode + this.state.phoneNumber);
    formData.append("vendor_account[email_address]", this.state.email);
    formData.append("vendor_account[address]", this.state.address);
    formData.append("vendor_account[activated]", this.state.portalActivated === "yes" ? "1" : "0");
    formData.append("vendor_account[taxpayer_name]", this.state.taxpayerName);
    formData.append("vendor_account[taxpayer_id]", this.state.taxpayerID);
    formData.append("vendor_account[send_1099]", this.state.send1099 === "yes" ? "1" : "0");
    this.handleDateSubmitting(formData);
    this.handleFilesSubmitting(formData);
    formData.append("vendor_account[use_online_payables]", this.state.useOnlinePayables === "yes" ? "1" : "0");
    formData.append("vendor_account[payment_type]", this.state.paymentType);
    formData.append("vendor_account[vendor_bank_routing_number]", this.state.vendorBankRoutingNumber);
    formData.append("vendor_account[vendor_bank_account_number]", this.state.vendorBankAccountNumber);
    formData.append("vendor_account[notes]", this.state.notes);
    this.addNewVendor(formData);
  };

  handlePopupClose = () => {
    this.setState({
      openSnack: false
    });
  };

  handlePaymentType = (payEvent: SelectChangeEvent<string>) => {
    this.setState({ paymentType: payEvent.target.value });
  };

  handleVendorType = (vendorEvent: SelectChangeEvent<string>) => {
    this.setState({ vendorType: vendorEvent.target.value });
  };

  handleRadioChange = (sendEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ send1099: (sendEvent.target as HTMLInputElement).value });
  };

  handlePortalChange = (sendEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ portalActivated: (sendEvent.target as HTMLInputElement).value });
  };

  handleFileDocChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>);
  };

  handleAccFileChange = (names: string, files: FileWithPreview[]) => {
    this.setState({
      filesAccountType: files,
      filesName: names
    });
  };
  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  validatesName = (name: string) => {
    const regex = configJSON.vendorRegax
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          ownerName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          ownerName: false,
        },
      }));
    }
  };

  validatesCompanyName = (name: string) => {
    const maxLength = 50;
    if (name.length > maxLength) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          companyName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          companyName: false,
        },
      }));
    }
  };

  validatesPhone = (phone: string) => {
    const phoneRegex = configJSON.vendornumber
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: false,
        },
      }));
    }
  };

  validatesBankRoutingNumber = (number: string) => {
    const routingRegex = configJSON.vendorPhone
    if (!routingRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankRoutingNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankRoutingNumber: false,
        },
      }));
    }
  };

  validateTrade = () => {
    if (this.state.vendorTrade.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: false,
        },
      }));
    }
  };

  validatesEmail = (email: string) => {
    const emailRegex = configJSON.emailRegex
    if (!emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: false,
        },
      }));
    }
  };

  validatesBankAccountNumber = (number: string) => {
    const accountRegex = configJSON.accountRegax
    if (!accountRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankAccountNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankAccountNumber: false,
        },
      }));
    }
  };

  validatesTaxpayerName = (name: string) => {
    const regex = configJSON.taxRegax
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerName: false,
        },
      }));
    }
  };

  validatesTaxpayerID = (id: string) => {
    const regex = configJSON.taxNumberIdRegax
    if (!regex.test(id)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerId: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerId: false,
        },
      }));
    }
  };

  getHelperText = (field: string) => {
    const { errors } = this.state;

    switch (field) {
      case "ownerName":
        return errors.ownerName ? "Please enter a valid owner name using letters and spaces only." : null;

      case "phoneNumber":
        return errors.phone ? "Please enter a valid phone number." : null;

      case "vendorBankRoutingNumber":
        return errors.bankRoutingNumber ? "The input must be between 9 and 34 characters long and contain only letters and numbers." : null;

      case "vendorBankAccountNumber":
        return errors.bankAccountNumber ? "The input must be between 9 and 34 characters long and contain only letters and numbers." : null;

      case "taxpayerName":
        return errors.taxpayerName ? "Please enter a valid taxpayer name using letters and spaces only." : null;

      case "taxpayerID":
        return errors.taxpayerId ? "Please enter a valid taxpayer Id using alphanumerics only." : null;

      case "email":
        return errors.email ? "Please enter a valid email address." : "example@example.com";
       
      case "companyName":
        return errors.companyName ? configJSON.companyMessage : null;
      default:
        return null;
    }
  };

  handleChange = (
    events: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = events.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "ownerName") {
      this.validatesName(value);
    }else if (name === "phoneNumber") {
      this.validatesPhone(value);
    } else if (name === "vendorBankRoutingNumber") {
      this.validatesBankRoutingNumber(value);
    } else if (name === "email") {
      this.validatesEmail(value);
    } else if (name === "vendorBankAccountNumber") {
      this.validatesBankAccountNumber(value);
    } else if(name === "taxpayerName") {
      this.validatesTaxpayerName(value);
    }  else if(name === "taxpayerID") {
      this.validatesTaxpayerID(value);
    }  else if(name === "companyName") {
      this.validatesCompanyName(value);
    }
  };

  handleTradeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    this.setState({
      trades: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleAddTrade = () => {
    const newTrades = this.state.trades
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    const combinedTrades = [...this.state.vendorTrade, ...newTrades];

    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState({ vendorTrade: uniqueTrades, trades: "" }, () => {
      this.validateTrade();
    });
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleAddTrade();
    }
  };

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.vendorTrade.filter(
      (item) => item !== trade
    );
    this.setState({ vendorTrade: updatedTrade });
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropdown: true });
  };

  handleCountryChange = (event: SelectChangeEvent<string>) => {
    this.setState({ areaCode: event.target.value, isCountryDropdown: false });
  };

  getAreaCode = async () => {
    this.getAreaCodeApiCallId = await this.newVendorApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getCountriesCodeAPIEndPoint
    });
  };

  apiSuccessCall = (apiRequestCallID: string, responseJson: Countries & Category) => {
    if (apiRequestCallID === this.getAreaCodeApiCallId) {
      let countriesCode = responseJson.data.map((item: AreasCode) => {
        return {
          name: item.attributes.name,
          emoji_flag: item.attributes.emoji_flag,
          country_code: item.attributes.country_code,
        };
      });

      this.setState({ countriesCode }, () => {});
    }

    if (apiRequestCallID === this.postAddVendorApiCallId) {
      this.setState({
        openSnack: true,
        errorMessage: "New vendor successfully registered",
        venMessage: "success"
      },()=>{
      this.handleVendorDashboard();
      });
    }

    if (apiRequestCallID === this.getVendorTypeApiCallId) {
      this.setState({
        vendorsItem: responseJson.data
      });
    }
  };

  newVendorApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type} = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestNewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );
    
    runEngine.sendMessage(requestNewMessage.id, requestNewMessage);
    return requestNewMessage.messageId;
  };

  receive = async (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestsCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCall(apiRequestsCallId, responseJson);
      }
    }
  };

  addNewVendor = async (formData: FormData) => {
    this.postAddVendorApiCallId = await this.newVendorApiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.addVendorApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  getVendorType = async () => {
    this.getVendorTypeApiCallId = await this.newVendorApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getVendorsTypeApiEndPoint
    });
  };
    // Customizable Area End
}
