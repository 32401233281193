import React from "react";

import {
  
  Box,
  
  Typography,
  // Customizable Area Start
  Grid, FormControl, Select, MenuItem, TextField, Divider, Radio, FormHelperText, styled, IconButton, Chip, Button
  // Customizable Area End

} from "@mui/material";

// Customizable Area Start
import { downArrow } from "../../blocks/portfoliomanagement/src/assets";
import { addIcon } from "../../blocks/portfoliomanagement/src/assets";
import Close from "@mui/icons-material/Close";
import FileUpload from "../src/FileUpload.web";
import { CustomRadioButton } from "../src/CustomRadioButton.web";
import CustomDatePickerData from "../src/CustomDatePickerData.web";
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import DocumentsFileUpload from "../../components/src/DocumentsFileUpload.web"
import EventIcon from '@mui/icons-material/Event';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

interface FileWithPreview extends File {
  preview: string;
}
interface CustomEnroll1Props {
  handleChangePersonal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddTrade: () => void;
  handleAddAmenities: () => void;
  formErrors: {
    rentalStateError: JSX.Element;
    petOtherStateError: JSX.Element;
    dogAllowStateError: JSX.Element;
    siteManagerError: JSX.Element;
    portfolioError: JSX.Element;
    renewalStateError: JSX.Element;
    classStateError: JSX.Element;
    propertyPropertyError: JSX.Element;
    propertyTypeError: JSX.Element;
    flioGuardError: JSX.Element;
    managementStartError: string;
    dateError: JSX.Element;
    lastnameError: JSX.Element;
    firstnameError: JSX.Element;
    descriptionsError: JSX.Element;
    gender1Error: JSX.Element;
    propertyAddressError: JSX.Element;
    amenitiesError: string;
    propertyNameError: string;
    propertyCountryError: string;
    propertyOtherPetsAllowedError: string;
    formFilesInsuranceError: JSX.Element;


  };
  formData: {
    rentalState: string;
    propertyTypeState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    petOtherState: string;
    dogAllowState: string;
    propertyName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    propertyCountry: (event: React.ChangeEvent<HTMLInputElement>) => void;
    propertyAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
    gender1: (event: React.ChangeEvent<HTMLInputElement>) => void;
    portfolioState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    propertyOtherpetsAllowed: (event: React.ChangeEvent<HTMLInputElement>) => void;
    lastName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dateYearbuilt: Date | null;
    classState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    manageMentStart: (event: React.ChangeEvent<HTMLInputElement>) => void;
    flioGuard: (event: React.ChangeEvent<HTMLInputElement>) => void;
    renewalState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    firstName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    anmitiesdata: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sitemanagerState: string;

    selectDataState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    descriPtions: (event: React.ChangeEvent<HTMLInputElement>) => void;





  };
  vendorTrade: string[];
  anmitiesData: string[];
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement>, name: string) => void;
  handleDelete: (name: string) => void;
  handleFilesChange: () => void;
  formFilesInsurance: FileWithPreview[];
  handleRenewalDateChange: () => void;
  renewalDate: Date | null;
  handleManageStartChange: () => void;
  manageStartDate: Date | null;
  handleRenewalChange: () => void;
  renewalDateData: Date | null;
  amenitiesValue: string;
  handleCountryCodeDropdown: ()=> void;
  handleCountryDropdownClose: ()=> void;
  handleCountryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mrValue: string;
}


const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },

  },
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: '#000000',
  },
  '& .MuiInputLabel-root': {
    color: '#BAB1AB',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#BAB1AB', 
    },
    '&:hover fieldset': {
      borderColor: '#BAB1AB', 
    },
    '&.Mui-focused fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.MuiOutlinedInput-root':{
      borderRadius:"8px",
      height: "44px",
    },
  },
}));

const CustomLabel = styled("label")({
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  fontFamily: "Outfit",
  color: "#544B45",
  whiteSpace: "nowrap"
});

const CustomBr = styled("br")({
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const AddButton =styled(Button)({
  fontSize: "14px",
  fontWeight: 700,
  whiteSpace: "nowrap",
  lineHeight: "22px",
  fontFamily: "Outfit",
  padding: "6px 0px",
  color: "#CC9200",
  textTransform: "none",
  gap: "5px",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center"
});
// Customizable Area End



export default class CustomEnroll1 extends React.Component<CustomEnroll1Props> {


  // Customizable Area Start

  // Customizable Area End

  render() {
    const { handleChangePersonal, mrValue, formErrors, formData, handleSelectChange, handleAddTrade, vendorTrade, handleKeyPress, handleDelete, handleAddAmenities, anmitiesData, handleFilesChange, formFilesInsurance, handleRenewalDateChange, renewalDate, handleManageStartChange, manageStartDate, handleRenewalChange, renewalDateData, amenitiesValue} = this.props;
    const propertyData = ["Property 1", "Property 2", "Property 3"];
    function DividerEnrolfirst() {
      return (
        <>
          <Grid container justifyContent="flex-end" sx={{width: {xs:"83%"}, marginTop: {  sm: "3rem" }, margin: {xs: "1rem 2rem"} }}>
            <Grid item md={12}  xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Divider sx={{ width: '100%' }} />
              </Box>
            </Grid>
          </Grid>
        </>
      )
    }
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{ display: "flex" }}>



          <Grid container sx={{
            padding: { xs: "0rem 2rem", sm: "2rem" }, 
            marginTop: "1rem", 
          }}>
            <Grid item md={3} xs={12}>
              <Box>
                <Typography style={webStyle.labeltextsyle2} sx={{
                  marginBottom: { xs: "1rem", md: "0" }, 
                }}>Property name and <CustomBr />address</Typography>
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Box style={{ borderRadius: "8px", position: "relative" }}>
                <label style={webStyle.labeltextsyle}>Property Type*</label>
                <StyledTextField
                  data-testId="propery-type"
                  select
                  fullWidth
                  name="propertyTypeState"
                  InputLabelProps={{ shrink: false }}
                  value={formData?.propertyTypeState || ""}
                  onChange={handleSelectChange}
                  style={{ paddingRight: "30px" }} 
                  SelectProps={{
                    IconComponent: () => null,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      appearance: "none", 
                    },
                  }}
                >
                  {propertyData.map((propData, index) => (
                    <MenuItem key={index} value={propData}>{propData}</MenuItem>
                  ))}
                </StyledTextField>
                <img
                  style={{
                    position: "absolute",
                    right: "13px",
                    top: "64%",
                    pointerEvents: "none"
                  }}
                  src={downArrow}
                  alt="down"
                />
              </Box>
              {formErrors.propertyPropertyError && (
                <span style={webStyle.ErrorStyleland}>{formErrors.propertyPropertyError}</span>
              )}
              <Box style={webStyle.marginBorderstyle}>

                <label style={webStyle.labeltextsyle}>Property Name</label>
                <StyledTextField
                data-testId="property-name"
                  name='propertyName'
                  value={formData?.propertyName}
                  onChange={handleChangePersonal}
                  data-test-id="propertytype"
                  variant="outlined"
                  fullWidth
                  sx={webStyle.marginManage}
                />
              </Box>
              {formErrors.propertyNameError && (
                <span style={webStyle.ErrorStyleland}>{formErrors.propertyNameError}</span>
              )}
              <Box style={webStyle.marginBorderstyle}>

                <label style={webStyle.labeltextsyle}>Address*</label>
                <StyledTextField
                data-testId="address"
                  name='propertyAddress'
                  value={formData.propertyAddress}
                  onChange={handleChangePersonal}

                  variant="outlined"
                  fullWidth
                  sx={webStyle.marginManage}
                />
              </Box>
              {formErrors.propertyAddressError && (
                <span style={webStyle.ErrorStyleland}>{formErrors.propertyAddressError}</span>
              )}
              <Box style={webStyle.marginBorderstyle}>

                <label style={webStyle.labeltextsyle}>Country*</label>
                <StyledTextField
                  data-testId="country-input"
                  name='propertyCountry'
                  value={formData.propertyCountry}

                  onChange={handleChangePersonal}


                  variant="outlined"
                  fullWidth
                  sx={webStyle.marginManage}
                />
              </Box>
              {formErrors.propertyCountryError && (
                <span style={webStyle.ErrorStyleland}>{formErrors.propertyCountryError}</span>
              )}
            </Grid>
          </Grid>
        </Box>
        <DividerEnrolfirst />

        <Grid container sx={{
          padding: { xs: "0rem 2rem", sm: "2rem" }, 
        }}>
          <Grid item md={3} xs={12}>
            <Box  >
              <Typography style={webStyle.labeltextsyle2} sx={{
                  marginBottom: { xs: "1rem", md: "0" }, 
                }}>
              {amenitiesValue}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <Box>
              <Box>
                <label style={webStyle.labeltextsyles}>Cats allowed</label>
              </Box>
              <FormControl component="fieldset" error={!!formErrors.gender1Error}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={formData.gender1}
                  sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                  onChange={handleChangePersonal}
                >
                  <FormControlLabel
                    value="1"
                    label={
                      <Typography 
                          fontFamily="Outfit"
                          variant="body1" 
                          fontSize="16px" 
                      >
                          Yes
                      </Typography>
                  }
                    control={<CustomRadioButton />}
                  />
                  <FormControlLabel
                    value="0"
                    label={
                      <Typography 
                          fontFamily="Outfit"
                          variant="body1" 
                          fontSize="16px" 
                      >
                          No
                      </Typography>
                  }
                    control={<CustomRadioButton />}
                  />
                </RadioGroup>
                {formErrors.gender1Error && (
                  <FormHelperText style={webStyle.ErrorStyleland}>{formErrors.gender1Error}</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Grid container style={{ marginTop: "3rem" }}>
              <Grid item md={3} xs={12}>
                <Box>
                  <Box>
                    <label style={webStyle.labeltextsyles}>Dogs allowed</label>
                  </Box>
                  <FormControl component="fieldset"

                  >

                    <RadioGroup aria-label="gender" name="dogAllowState"
                      value={formData.dogAllowState}
                      data-testId="dogs-allow"

                      onChange={handleChangePersonal} >
                      <FormControlLabel value="1" control={<CustomRadioButton />} label={
                      <Typography 
                          variant="body1" 
                          fontFamily="Outfit"
                          fontSize="16px" 
                      >
                          Yes
                      </Typography>
                  } />
                      <FormControlLabel value="0" control={<CustomRadioButton />} label={
                      <Typography 
                          variant="body1" 
                          fontSize="16px" 
                          fontFamily="Outfit"
                      >
                          No
                      </Typography>
                  } />

                    </RadioGroup>
                    {formErrors.dogAllowStateError && (
                      <FormHelperText style={webStyle.ErrorStyleland}>{formErrors.dogAllowStateError}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              {formData.dogAllowState === "1" ? (
                <Grid item md={9} xs={12}>
                <Box style={{ borderRadius: "8px", position:"relative" }}>
                  <label style={webStyle.labeltextsyle}>Dog Type</label>
                  <StyledTextField
                    select

                    fullWidth
                    name="selectDataState"
                    InputLabelProps={{ shrink: false }}
                    value={formData.selectDataState}
                    onChange={handleSelectChange}
                    data-testId="dog-type"
                  >
                    <MenuItem value="Child">Child</MenuItem>
                    <MenuItem value="Sister">Sister</MenuItem>
                    <MenuItem value="Brother">Brother</MenuItem>
                    <MenuItem value="Nephew">Nephew</MenuItem>
                    <MenuItem value="Niece">Niece</MenuItem>
                  </StyledTextField>
                  <img
                  style={{
                    position: "absolute",
                    right: "13px",
                    top: "64%",
                    pointerEvents: "none"
                  }}
                  src={downArrow}
                  alt="down"
                />
                </Box>
                {formErrors.propertyTypeError && (
                  <FormHelperText style={webStyle.ErrorStyleland}>{formErrors.propertyTypeError}</FormHelperText>
                )}
              </Grid>
              ) : ""}
            </Grid>
            <Grid container style={{ marginTop: "3rem" }}>
              <Grid item md={3} xs={12}>
                <Box>
                  <Box>
                    <CustomLabel style={webStyle.labeltextsyles}>Other pets allowed</CustomLabel>
                  </Box>
                  <FormControl component="fieldset">

                    <RadioGroup aria-label="gender" name="petOtherState"
                      value={formData.petOtherState}

                      onChange={handleChangePersonal}
                    >
                      <FormControlLabel label={
                      <Typography 
                          fontFamily="Outfit"
                          variant="body1" 
                          fontSize="16px" 
                      >
                          Yes
                      </Typography>
                  } value="1" control={<CustomRadioButton />} />
                      <FormControlLabel
                       label={
                        <Typography 
                            fontFamily="Outfit"
                            variant="body1" 
                            fontSize="16px" 
                        >
                            No
                        </Typography>
                    } value="0" control={<CustomRadioButton />} />

                    </RadioGroup>
                    {formErrors.petOtherStateError && (
                      <FormHelperText style={webStyle.ErrorStyleland}>{formErrors.petOtherStateError}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              {formData.petOtherState === "1" ? (
                 <Grid item md={9} xs={12}>
                 <Box style={{ borderRadius: "8px" }}>
                   <TextField
                    data-testId="otherpet-textfield"
                     placeholder="Separate with comma"
                     variant="outlined"
                     fullWidth
                     name='propertyOtherpetsAllowed'
                     value={formData.propertyOtherpetsAllowed}
                     onChange={handleChangePersonal}
                     onKeyPress={(event:React.KeyboardEvent<HTMLDivElement>) => handleKeyPress(event,"otherpet")}
                     sx={webStyle.marginManage}
                     InputProps={{
                       endAdornment: (
                         <InputAdornment position="end">
                           <Box
                             sx={{
                               border: "4px solid #DADADA",
                               borderRadius: '50%',
                               fontWeight: 700,
                               cursor: "pointer"
 
                             }}
                           >
                             <AddIcon data-testId="add-btn" onClick={handleAddTrade} sx={webStyle.addBtn}/>
                           </Box>
                         </InputAdornment>
                       ),
                     }}
                   />
                 </Box>
                 {formErrors.propertyOtherPetsAllowedError && (
                   <span style={webStyle.ErrorStyleland}>{formErrors.propertyOtherPetsAllowedError}</span>
                 )}
                 <Box mt={2}>
                   {vendorTrade.map((trade:string, index :number) => (
                     <Chip
                       key={index}
                       label={trade}
                       variant="outlined"
                       icon={
                         <IconButton
                           aria-label="delete"
                           data-test-id="deleteIconButton"
                           data-testId="delete-btn"
                           size="small"
                           onClick={() => handleDelete(trade)}
                           sx={{ mr: -1 }}
                         >
                           <Close fontSize="small" />
                         </IconButton>
                       }
                       sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                     />
                   ))}
                 </Box>
               </Grid>
              ) : ("")}
            </Grid>
            <Grid item md={12}>
              <Box style={webStyle.marginBorderstyle}>

                <label style={webStyle.labeltextsyle}>Amenities</label>
                <TextField
                  data-test-id="anmitiesdata"
                  data-testId="amenties-field"
                  variant="outlined"
                  placeholder="Separate with comma"
                  fullWidth
                  name='anmitiesdata'
                  value={formData.anmitiesdata}
                  onChange={handleChangePersonal}
                  onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => handleKeyPress(event, "amenties")}
                  sx={webStyle.marginManage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{
                            border: "4px solid #DADADA",// Adjust color as needed
                            borderRadius: '50%',
                            fontWeight: 700,
                            cursor: "pointer"

                          }}
                        >
                          <AddIcon onClick={handleAddAmenities}/>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {formErrors.amenitiesError && (
                <span style={webStyle.ErrorStyleland}>{formErrors.amenitiesError}</span>
              )}
                <Box mt={2}>
                  {anmitiesData.map((anmities:string, index :number) => (
                    <Chip
                      key={index}
                      label={anmities}
                      variant="outlined"
                      icon={
                        <IconButton
                          aria-label="delete"
                          data-test-id="deleteIconButton"
                          size="small"
                          sx={{ mr: -1 }}
                        >
                          <Close fontSize="small" onClick={() => handleDelete(anmities)}/>
                        </IconButton>
                      }
                      sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                    />
                  ))}
                </Box>


            </Grid>
          </Grid>
        </Grid>

        <DividerEnrolfirst />

        <Grid container sx={{
          padding: { xs: "0rem 2rem", sm: "2rem" }, 
          borderRadius: "8px", 
          marginTop: { xs: "0rem", sm: "2rem" }, 
        }}>
          <Grid item md={3} xs={12}>
            <Box>
              <Typography style={webStyle.labeltextsyle2} sx={{
                  marginBottom: { xs: "1rem", md: "0" }, 
                }}>Property <CustomBr /> information</Typography>
            </Box>
          </Grid>
          <Grid item md={7} xs={12} >
            <Box>

              <label style={webStyle.labeltextsyle}>Description</label>
              <StyledTextField
                data-testId="desc-field"
                name='descriPtions'
                value={formData.descriPtions}
                onChange={handleChangePersonal}


                variant="outlined"
                fullWidth
                sx={webStyle.marginManage}
              />
            </Box>
            {formErrors.descriptionsError && (
              <span style={webStyle.ErrorStyleland}>{formErrors.descriptionsError}</span>
            )}
            <Box style={webStyle.marginBorderstyle} >
              <Box style={{ position:"relative"}}>
              <label style={webStyle.labeltextsyle}>Portfolio</label>
              <StyledTextField
                data-testId="portfolio-select"
                select

                fullWidth
                name="portfolioState"
                InputLabelProps={{ shrink: false }}
                SelectProps={{
                  IconComponent: () => null,
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    appearance: "none", 
                  },
                }}
                value={formData.portfolioState}
                onChange={handleSelectChange}
              >
                <MenuItem value="Portfolio1">Portfolio1</MenuItem>
                <MenuItem value="Portfolio2">Portfolio2</MenuItem>
                <MenuItem value="Portfolio3">Portfolio3</MenuItem>
                <MenuItem value="Portfolio4">Portfolio4</MenuItem>
                <MenuItem value="Portfolio5">Portfolio5</MenuItem>
              </StyledTextField>
              <img
                style={{
                  position: "absolute",
                  right: "13px",
                  top: "64%",
                  pointerEvents: "none"
                }}
                src={downArrow}
                alt="down"
              />
              </Box>
            </Box>
            {formErrors.portfolioError && (
              <span style={webStyle.ErrorStyleland}>{formErrors.portfolioError}</span>
            )}

            <Grid container spacing={4} marginTop= {"0.5rem"} display= {'flex'} flexWrap={'wrap'}>
              <Grid item md={4} xs={12} style={{ display: 'flex', alignItems: 'flex-start', flexDirection: "column" }}>
                  <label style={webStyle.labelstextsyle}>Site manager</label>
                <Box width={"100%"}>
                  <StyledTextField
                    data-testId="site-manager"
                    select
                    sx={webStyle.marginManage}
                    fullWidth
                    name="sitemanagerState"
                    value={formData.sitemanagerState}
                    onChange={handleSelectChange}
                    label={formData.sitemanagerState === '' ? 'Prefix' : ''}
                    InputLabelProps={{
                      shrink: false,
                      style: { color: '#A3978F', lineHeight: "0.438em", padding: "2.5"}
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ExpandMoreIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        "& .MuiSelect-icon": {
                          display: "none",
                        },
                      },
                    }}
                  >
                    <MenuItem value="Mrs">Mrs</MenuItem>
                    <MenuItem value="Miss">Miss</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Mr">{mrValue}</MenuItem>
                  </StyledTextField>
                {formErrors.siteManagerError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.siteManagerError}</span>
                )}
                </Box>
              </Grid>
              <Grid item md={4} xs={6} style={{ display: 'flex', alignItems: 'center'}} sx={{
                  marginTop: { xs: "-1rem", sm: "1.4rem" }, 
                }}
                >
                <Box style={{ width: '100%' }}>
                  <StyledTextField
                    data-testId="first-name"
                    variant="outlined"
                    placeholder="First name"
                    fullWidth
                    sx={webStyle.sitemaneger}
                    name='firstName'
                    value={formData.firstName}
                    onChange={handleChangePersonal}
                  />
                {formErrors.firstnameError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.firstnameError}</span>
                )}
                </Box>
              </Grid>
              <Grid item md={4} xs={6} style={{ display: 'flex', alignItems: 'center'}} sx={{
                  marginTop: { xs: "-1rem", sm: "1.4rem" },
                }}>
                <Box style={{ width: '100%' }}>
                  <StyledTextField
                    data-testId="last-name"
                    variant="outlined"
                    placeholder="Last name"
                    fullWidth
                    sx={webStyle.sitemaneger}
                    name='lastName'
                    value={formData.lastName}
                    onChange={handleChangePersonal}
                  />
                {formErrors.lastnameError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.lastnameError}</span>
                )}
                </Box>
              </Grid>
            </Grid>



            <Grid container style={webStyle.marginBorderstyle}>
              <Grid item md={12} xs={12}>
                <Box >
                  <label style={webStyle.labeltextsyles}>Year built</label>
                   <CustomDatePickerData
                      value={renewalDate}
                      required={false}
                      onChange={handleRenewalDateChange} 
                    />
                </Box>
                {formErrors.dateError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.dateError}</span>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={12} xs={12}>
                <Box style={webStyle.marginBorderstyle}>
                  <Box style={{ position:"relative"}}>
                  <label style={webStyle.labeltextsyle}>Class</label>
                  <StyledTextField
                    select

                    fullWidth
                    name="classState"
                    InputLabelProps={{ shrink: false }}
                    value={formData.classState}
                    onChange={handleSelectChange}
                    data-testId="class-select"
                    SelectProps={{
                      IconComponent: () => null,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        appearance: "none", 
                      },
                    }}
                  >
                    <MenuItem value="class1">class1</MenuItem>
                    <MenuItem value="class2">class2</MenuItem>
                    <MenuItem value="class3">class3</MenuItem>
                    <MenuItem value="class4">class4</MenuItem>
                    <MenuItem value="class5">class5</MenuItem>
                  </StyledTextField>
                  <img
                    style={{
                      position: "absolute",
                      right: "13px",
                      top: "64%",
                      pointerEvents: "none"
                    }}
                    src={downArrow}
                    alt="down"
                  />
                  </Box>
                </Box>
                {formErrors.classStateError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.classStateError}</span>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={12} xs={12}>
                <Box style={webStyle.marginBorderstyle}>
                  <label style={webStyle.labeltextsyles}>Management start</label>
                  <CustomDatePickerData
                      value={manageStartDate}
                      required={false}
                      onChange={handleManageStartChange} 
                    />
                </Box>
                {formErrors.managementStartError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.managementStartError}</span>
                )}

              </Grid>
            </Grid>

            <Box style={webStyle.marginBorderstyle}>
              <Box>
                <label style={webStyle.labeltextsyles}>FolioGuard Policy</label>
              </Box>
              <FormControl component="fieldset" error={!!formErrors.gender1Error}>
              <RadioGroup aria-label="gender" name="flioGuard"
                  value={formData.flioGuard}
                  data-testId="rental-radio"
                  onChange={handleChangePersonal} >
                  <FormControlLabel value="1" label={
                      <Typography 
                          fontSize= "16px" 
                          fontFamily= "Outfit"
                          variant= "body1" 
                      >
                          Yes
                      </Typography>
                  }  control={<CustomRadioButton />} />
                  <FormControlLabel value="0" label={
                      <Typography 
                          fontFamily= "Outfit"
                          fontSize= "16px" 
                          variant= "body1" 
                      >
                          No
                      </Typography>
                  }  control={<CustomRadioButton />} />

              </RadioGroup>


                {formErrors.flioGuardError && (
                  <FormHelperText style={webStyle.ErrorStyleland}>{formErrors.flioGuardError}</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Grid container style={{ marginTop: "0.9rem" }}>
              <Grid item md={3} xs={12}>
                <Box>
                  <Box>
                    <label style={webStyle.labeltextsyles}>Rental license</label>
                  </Box>
                  <FormControl component="fieldset"

                  >

                    <RadioGroup aria-label="gender" name="rentalState"
                      value={formData.rentalState}
                      data-testId="rental-radio"
                      onChange={handleChangePersonal} >
                      <FormControlLabel value="1" control={<CustomRadioButton />} label={
                      <Typography 
                          fontFamily ="Outfit"
                          fontSize ="16px" 
                          variant ="body1" 
                      >
                          Yes
                      </Typography>
                  }  />
                      <FormControlLabel value="0" control={<CustomRadioButton />} label={
                      <Typography 
                          fontFamily ="Outfit"
                          variant ="body1" 
                          fontSize ="16px" 
                      >
                          No
                      </Typography>
                  }  />

                    </RadioGroup>
                    {formErrors.rentalStateError && (
                      <FormHelperText style={webStyle.ErrorStyleland}>{formErrors.rentalStateError}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
              {formData.rentalState === "1" ? (
                <Grid item md={9} xs={12}>
                  <Grid item md={3} xs={12}>
                      <Box  >
                        <label style={webStyle.labeltext} className="nameText">Renewal date</label>
                    </Box>
                    </Grid>
                 <CustomDatePickerData
                    value={renewalDateData}
                    required={false}
                    onChange={handleRenewalChange} 
                  />
                  {formErrors.renewalStateError && (
                    <span style={webStyle.ErrorStyleland}>{formErrors.renewalStateError}</span>
                  )}
                <AddButton>
                  <img src={addIcon}/>Add reminder</AddButton>
              </Grid>
              ) : ""}
            </Grid>
            <Grid container justifyContent="center" style={webStyle.marginBorderstyle}>

              <Grid item md={12} xs={12}>
              <FileUpload
                  label=""
                  name="formW9Files"
                  files={formFilesInsurance}
                  onFilesChange={handleFilesChange}
                  />
                  </Grid>
            </Grid>
                  {formErrors.formFilesInsuranceError && (
                  <span style={webStyle.ErrorStyleland}>{formErrors.formFilesInsuranceError}</span>
                )}
          </Grid>
        </Grid>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  ErrorStyleland: {
    color: 'rgb(211, 47, 47)', fontFamily: "Outfit", fontWeight: 400, fontSize: "0.75rem"
  },
  marginManage: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      fontFamily: "Outfit",
      height:"44px"
      // 8px border-radius
    }
  },
  sitemaneger: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      fontFamily: "Outfit",
      marginTop: "0rem"
      // 8px border-radius
    }
  },
  marginBorderstyle: {
    borderRadius: "8px", marginTop: "2rem",
    "@media(max-width: 600px)": {
      marginTop: "0rem"
    }
  },
  labeltextsyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    marginBottom: "2rem",
    color:"#544B45",
    "@media(max-width: 600px)": {
    marginBottom: "0rem"
    }
  },
  labelstextsyle: {
    fontSize: "14px",
    color:"#544B45",
    fontFamily: "Outfit",
    fontWeight: 700,
    lineHeight: "22px",
    "@media(max-width: 600px)": {
    marginBottom: "0rem"
    }
  },
  labeltextsyle2: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    fontFamily: "Outfit",
    color: "#BAB1AB",
    display: "flex"
  },
  addstyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    color: '#CC9200'

  },
  labeltextsyles: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    color: "#544B45"

  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    marginTop: 20,
    marginBottom: 20,
  },
  radiocolorstyle: {
    color: "#FFC123",
    "&.Mui-checked": { color: "#CC9200"}
  },
  fileWrapper: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    flex: 1,
  },
  submitBtnStyle: {
    marginTop: 20,
  },
  uploadBtnWrapper: {
    display: "flex",
    marginTop: 20,
  },
  uploadBtn: {
    padding: 7,
    paddingLeft: 15,
    paddingRight: 15,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#fff",
    borderRadius: 5,
    cursor: "pointer",
    marginRight: 15,
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  bulkFileWrapper: {
    border: "1px solid #ccc",
    padding: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  bulkFileTopWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textWrapper: {
    flex: 1,
  },
  fileItemWrapper: {
    display: "flex",
    border: "1px solid #ccc",
    padding: 10,
    marginTop: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  marginManageData:{
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
      marginTop: "7px"
    }
  },
  hideDefaultIcon: {
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  addBtn: {
    "& .css-19ej0hv": {
      border: "2px solid #B38C8C",
    }
  },
  labeltext: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "17px",
    fontFamily: "Outfit",
    color: "#544B45",
  },
};
// Customizable Area End
