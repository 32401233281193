import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { AlertColor } from "@mui/material";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

interface PaymentData {
  status: number;
  last_payment: string;
  last_transaction_date: string | null;
  upcoming_payment_date: string;
  pending_amount: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Handle {
  x?: string | number;
  y?: string | number;
  width?: string | number;
  height?: string | number;
  rx?: string | number;
  ry?: string | number;
  opacity?: string | number;

}

interface Types {
  labels: string[],
  datasets: [{
    data: number[],
    color: (opacity: number) => string,
    strokeWidth: number,
  }]
}


interface Marked {
  marked: boolean,
  color: string,
  textColor: string,
  startingDay: boolean,
  endingDay: boolean,
}

type MarkedType = {
  [keyName: string]: Marked;
};

interface CommitsData {
  date: string,
  count: number
}

interface PieData {
  name: string,
  population: number,
  color: string,
  legendFontColor: string,
  legendFontSize: number
}

interface DropType {
  label: string,
  value: string,
  name: string
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data: Types
  data1: Types
  value: string
  value1: string
  value2: string
  value3: string
  markedDate: {}
  pieData: PieData[]
  commitsData: CommitsData[]
  handleTip: Handle
  dropData: DropType[]
  dropData1: DropType[]
  dropData2: DropType[]
  dropData3: DropType[]
  showCalendar: boolean
  startDay: string,
  endDay: string,
  selectedToggle: string
  selectedChart: string
  datesArray: string[]
  activeTenantItem: string;
  openTenantDrawer: boolean;
  popUpOpen: boolean;
  popUpType: AlertColor;
  popUpMessage: string;
  paymentHistory: any[];
  paymentData: PaymentData;
  currentPage: number;
  rowsPerPage: number;
  paginatedRows: any[];
  searchInput: string;
  startDateOpen: boolean;
  endDateOpen: boolean;
  startDate: any;
  endDate: any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BaselineReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChartDataId: string;
  getPaymentDetailsApiCallId: string = "";
  getPaymentHistoryApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.getChartDataId = ''
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            data: [20, 45, 28, 80, 99, 43, 77],
            color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`, // optional
            strokeWidth: 2 // optional
          }
        ],
      },
      data1: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            data: [44, 11, 88, 22, 9, 79, 36],
            color: (opacity = 1) => `rgba(134, 65, 244, ${opacity})`, // optional
            strokeWidth: 2 // optional
          }
        ],
      },
      value: '',
      value1: '',
      value2: '',
      value3: '',
      markedDate: {},
      pieData: [
        {
          name: "One",
          population: 32,
          color: "#899",
          legendFontColor: "#7F7F7F",
          legendFontSize: 15
        },
        {
          name: "Two",
          population: 35,
          color: "#ccc",
          legendFontColor: "#7F7F7F",
          legendFontSize: 15
        },
      ],
      commitsData: [
        { date: "2023-06-02", count: 7 },
        { date: "2023-06-15", count: 9 },
        { date: "2023-04-02", count: 7 },
        { date: "2023-04-24", count: 9 },
        { date: "2023-02-02", count: 7 },
        { date: "2023-02-27", count: 9 },
      ],
      handleTip: {},
      dropData: [
        { label: 'Today', value: '1', name: 'Today' },
        { label: 'Weekly', value: '2', name: 'Week' },
        { label: 'Monthly', value: '3', name: 'Month' },
        { label: 'Custom', value: '4', name: 'Custom' }
      ],
      dropData1: [
        { label: 'Today', value: '1', name: 'Today' },
        { label: 'Weekly', value: '2', name: 'Week' },
        { label: 'Monthly', value: '3', name: 'Month' }
      ],
      dropData2: [
        { label: 'Today', value: '1', name: 'Today' },
        { label: 'Weekly', value: '2', name: 'Week' },
        { label: 'Monthly', value: '3', name: 'Month' },
      ],
      dropData3: [
        { label: 'Today', value: '1', name: 'Today' },
        { label: 'Weekly', value: '2', name: 'Week' },
        { label: 'Monthly', value: '3', name: 'Month' }
      ],
      showCalendar: false,
      startDay: '',
      endDay: '',
      selectedToggle: '',
      selectedChart: '',
      datesArray: [],
      activeTenantItem: "BaselineReporting",
      openTenantDrawer: false,
      popUpOpen: false,
      popUpType: "error",
      popUpMessage: "",
      paymentHistory: [],
      paymentData: {
        status: 0,
        last_payment: " --",
        last_transaction_date: null,
        upcoming_payment_date: "",
        pending_amount: " --"
      },
      currentPage: 1,
      rowsPerPage: 12,
      paginatedRows: [],
      searchInput: "",
      startDate:null,
      endDate:null,
      startDateOpen:false,
      endDateOpen:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getChartDataId) {
        this.populateResponse(responseJson)
      }
    }
    this.handleGetPaymentDetailsResponse(message);
    this.handleGetPaymentHistoryResponse(message);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  changeDrop(item: string, type: string) {
    if (type === configJSON.line) {
      this.setState({
        value: item,
        selectedToggle: item,
        selectedChart: type,
        showCalendar: item === '4' ? true : false
      }, () => {
        if (item !== '4') this.getAllMessages()
      });
    } else if (type === configJSON.bar) {
      this.setState({ value1: item, selectedToggle: item, selectedChart: type }, () => this.getAllMessages());
    } else if (type === configJSON.region) {
      this.setState({ value2: item, selectedToggle: item, selectedChart: type }, () => this.getAllMessages());
    } else {
      this.setState({ value3: item, selectedChart: type, selectedToggle: item }, () => this.getAllMessages());
    }
  }

  populateResponse(responseJson: { data: [] }) {
    if (this.state.selectedChart === configJSON.line) this.populateLine(responseJson)
    if (this.state.selectedChart === configJSON.bar) this.populateBar(responseJson)
    if (this.state.selectedChart === configJSON.region) this.populateRegion(responseJson)
    if (this.state.selectedChart === configJSON.pie) this.populatePie(responseJson)
  }

  populateLine(responseJson: { data: [] }) {
    let label: string[] = [''];
    let regionData: [{
      data: number[], color: (opacity: number) => string,
      strokeWidth: number,
    }] = [...this.state.data.datasets]
    if (this.state.selectedToggle === '1' || this.state.selectedToggle === '2') {
      regionData.forEach((innerData) => {
        innerData.data = responseJson.data
        label = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        return innerData
      })

      let objData = {
        labels: label,
        datasets: regionData,
      }
      this.setState({ data: objData })
    } else {
      regionData.forEach((innerData) => {
        innerData.data = responseJson.data
        label = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
        return innerData
      })

      let objData = {
        labels: label,
        datasets: regionData,
      }
      this.setState({ data: objData })
    }
  }

  populateBar(responseJson: { data: [] }) {
    let label: string[] = [''];
    let regionData: [{
      data: number[], color: (opacity: number) => string,
      strokeWidth: number,
    }] = [...this.state.data1.datasets]
    if (this.state.selectedToggle === '1' || this.state.selectedToggle === '2') {
      regionData.forEach((innerData) => {
        innerData.data = responseJson.data
        label = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        return innerData
      })

      let objData = {
        labels: label,
        datasets: regionData,
      }
      this.setState({ data1: objData })
    } else {
      regionData.forEach((innerData) => {
        innerData.data = responseJson.data
        label = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
        return innerData
      })

      let objData = {
        labels: label,
        datasets: regionData,
      }
      this.setState({ data1: objData })
    }
  }

  populateRegion(responseJson: { data: [] }) {
    let initialDate = moment().format('YYYY-MM-DD')
    let randomDates: string[] = [initialDate]

    for (let label = 0; label <= responseJson.data.length; label++) {
      if (this.state.selectedToggle === '3') {
        randomDates.push(moment(randomDates[label]).subtract(1, 'months').toString())
      }
      else {
        randomDates.push(moment(randomDates[label]).subtract(1, 'days').toString())
      }
    }

    this.setState({ datesArray: randomDates })
    let dateData: CommitsData[] = []
    responseJson.data.forEach((item, indexValue) => {
      dateData.push({
        date: moment(this.state.datesArray[indexValue]).format('YYYY-MM-DD'),
        count: item > 9 ? 9 : item
      })
    })
    this.setState({ commitsData: dateData })
  }

  populatePie(responseJson: { data: [] }) {
    let pieData: PieData[] = []
    responseJson.data.forEach((item, indexValue) => {
      pieData.push({
        name: '',
        population: item,
        color: '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
        legendFontColor: "#7F7F7F",
        legendFontSize: 15
      })
    })
    this.setState({ pieData: pieData })
  }

  selectDate = (days: { dateString: string }) => {
    if (this.state.startDay && !this.state.endDay) {
      const date: MarkedType = {}
      for (const date1 = moment(this.state.startDay); date1.isSameOrBefore(days.dateString); date1.add(1, 'days')) {
        date[date1.format('YYYY-MM-DD')] = {
          marked: true,
          color: 'black',
          textColor: 'white',
          startingDay: false,
          endingDay: false
        };
        if (date1.format('YYYY-MM-DD') === this.state.startDay) date[date1.format('YYYY-MM-DD')].startingDay = true;
        if (date1.format('YYYY-MM-DD') === days.dateString) date[date1.format('YYYY-MM-DD')].endingDay = true;

      }
      this.setState({ markedDate: date, endDay: days.dateString, startDay: '', })
      setTimeout(() => {
        this.setState({ showCalendar: false })
      }, 900);
      this.getAllMessages()
    }
    else {
      this.setState({
        startDay: days.dateString,
        endDay: '',
        markedDate: {
          [days.dateString]: {
            marked: true,
            color: 'black',
            textColor: 'white',
            startingDay: true,
            endingDay: true
          }
        },
      })
      this.getAllMessages()
    }
  }

  async getAllMessages() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    let frame = ''
    let customFrame = {}
    if (this.state.selectedToggle === '1') frame = configJSON.today;

    if (this.state.selectedToggle === '2') frame = configJSON.week;

    if (this.state.selectedToggle === '3') frame = configJSON.month;

    if (this.state.selectedToggle === '4') {
      customFrame = {
        start_date: moment(this.state.startDay).format('DD-MM-YYYY'),
        end_date: this.state.endDay ? moment(this.state.endDay).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')
      }
    }

    const httpBody = {
      "timeframe": this.state.selectedToggle === '4' ? customFrame : frame
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChartDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baselineApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getPaymentDetails();
    this.getPaymentHistory();
  }

  handleTenantitemClick = (tenantKey: string) => {
    this.setState({ activeTenantItem: tenantKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleTenantDrawer = () => {
    this.setState({
        openTenantDrawer: !this.state.openTenantDrawer
    });
  };

  handleWorkOrderMenu = () => {
    const { activeTenantItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeTenantItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  handlePopupMessageClose = () => {
    this.setState({
      popUpOpen: false,
    });
  };

  handleSortRequest = (property: string, order: string) => {
    const paymentHistoryCopy = [...this.state.paymentHistory];
    paymentHistoryCopy.sort((a, b) => {
      if (a[property] < b[property]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    this.setState({ paymentHistory: paymentHistoryCopy});
  };

  handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage });
    const { rowsPerPage } = this.state;

    const paginatedRows = this.state.paymentHistory.slice(
        (newPage - 1) * rowsPerPage,
        newPage * rowsPerPage
    );
    this.setState({ paginatedRows: paginatedRows });
  };

  formatDate = (dateString: string) => {
    if(dateString){
      const dateObject = new Date(dateString);

      const day = String(dateObject.getUTCDate()).padStart(2, '0');
      const month = String(dateObject.toLocaleString('default', { month: 'short' }));
      const year = dateObject.getUTCFullYear();

      return `${day} ${month} ${year}`;
    } else {
      return "No date selected";
    }
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleDateChange =(newDate: Date | null,field:string)=>{
    if(newDate){
       if(field==="startDate"){
      this.setState({ startDate: newDate });
       }else{
      this.setState({ endDate: newDate });
       }
    }
  };

  handleOpen = (field: string) => {
    if (field === "startDate") {
        this.setState({ startDateOpen: true, endDateOpen: false });
    } else if (field === "endDate") {
        this.setState({ startDateOpen: false, endDateOpen: true });
    }
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleFilter();
    }
  };

  handleFilter = async() => {
    const token = await getStorageData("authToken")
    const header = {
      token: token,
     'Content-Type': 'application/json',
    };
    let paymentHistoryApiEndpoint = configJSON.getPaymentHistoryApiEndPoint + '?';
    const { startDate, endDate, searchInput } = this.state;
    const body: any = {};

    if (startDate) {
      paymentHistoryApiEndpoint = paymentHistoryApiEndpoint + 'start_date=' + startDate.format('YYYY-MM-DD') + '&';
    }

    if (endDate) {
      paymentHistoryApiEndpoint = paymentHistoryApiEndpoint + 'end_date=' + endDate.format('YYYY-MM-DD') + '&';
    }

    if(searchInput) {
      paymentHistoryApiEndpoint = paymentHistoryApiEndpoint + 'query=' + searchInput;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentHistoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      paymentHistoryApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNewTransaction=()=>{
    const toMsgOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "NewTransaction"
    );
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgOpenWork);
  };

  getPaymentDetails = async() => {
    const token = await getStorageData("authToken");

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPaymentDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetPaymentDetailsResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getPaymentDetailsApiCallId && responseJson?.status) {
      this.setState({ paymentData: responseJson });
    } else if(responseJson?.errors) {
        this.setState({
          popUpOpen: true,
          popUpType: "error",
          popUpMessage: responseJson?.errors[0].token ? "Session Expired, Please Log in again." : responseJson?.errors
        });
    }
  };

  getPaymentHistory = async() => {
    const token = await getStorageData("authToken");

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentHistoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPaymentHistoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetPaymentHistoryResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getPaymentHistoryApiCallId && responseJson?.data) {
      if(responseJson?.data?.length){
        const formattedResponse = responseJson?.data?.map((item: any) => {
          return {
            id: item.attributes.id,
            transaction_date: item.attributes.transaction_date,
            property: `${item.attributes.property_name} - ${item.attributes.unit_name}`,
            description: item.attributes.ledger_type,
            details: item.attributes.details,
            amount: item.attributes.amount,
            transaction_status: item.attributes.transaction_status,
          };
        });
        this.setState({ paymentHistory: formattedResponse });
        const { currentPage, rowsPerPage } = this.state;

        const paginatedRows = formattedResponse.slice(
            (currentPage - 1) * rowsPerPage,
            currentPage * rowsPerPage
        );
        this.setState({ paginatedRows: paginatedRows });
      }
      else {
        this.setState({ paymentHistory: [], paginatedRows: [] });
      }
    } else if(responseJson?.errors) {
        this.setState({
          popUpOpen: true,
          popUpType: "error",
          popUpMessage: responseJson?.errors[0].token ? "Session Expired, Please Log in again." : responseJson?.errors
        });
    }
  };
  // Customizable Area End
}
