import React, { ReactNode } from "react";

import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { BlockComponent } from "../../framework/src/BlockComponent";
import RestApiClientBlock from "../../framework/src/Blocks/RestApiClientBlock";

const globalConfig = require("../../framework/src/config");
const baseURL = globalConfig.baseURL;

interface Props {
  children: ReactNode;
}
interface State {}
interface SS {}

export interface IRequest {
  body: any;
  url: string;
  headers: any;
  method: string;
  bodyType: string; // form | json | none
}

export const initializeMockResponses = (getMockedResponses: Function) => {
  // @ts-ignore
  window.fetch = (url: string, data: any) => {
    url = String(url).replace(baseURL, "");

let bodyType = "none";
if (data.body) bodyType = data.body instanceof FormData ? "form" : "json";

let request: IRequest = {
  url,
  bodyType,
  ...data,
};

let mockedResponse = getMockedResponses(request) ?? {
  status: 200,
  message: "Mock response not configured for the request",
};

return Promise.resolve({
  json: () => mockedResponse,
});
  };
  // @ts-ignore
  window.Response = class test {};
};

class MockAPIHelperBlock extends BlockComponent<Props, State, SS> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIRequestMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message) {
    let apiServerBlock = RestApiClientBlock.getInstance();
    apiServerBlock.receive(from, message);
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export default MockAPIHelperBlock;
// Utility function to convert response to File
export async function convertResponseToFile(url: string, fileName: string, mimeType: string, fileId: string): Promise<File | null> {
  try {
    const validUrl = new URL(url).toString();
    const response = await fetch(validUrl);

    if (!response.ok) {
      throw new Error("Failed to fetch file");
    }

    const blob = await response.blob();

    const file = new File([blob], fileName || "unnamed-file", {
      type: mimeType || "application/octet-stream",
      lastModified: Date.now(),
    });

    const previewUrl = URL.createObjectURL(blob);

    Object.defineProperty(file, "preview", {
      value: previewUrl,
      writable: true,
      enumerable: true,
      configurable: true,
    });

    Object.defineProperty(file, "id", {
      value: fileId,
      writable: true,
      enumerable: true,
      configurable: true,
    });

    return file;
  } catch (error) {
    console.error("Error processing file:", error);
    return null;
  }
}

export function validateFrequency(value:any){
  return value !== null && value !== undefined && value.trim() !== '' && value !== 'Frequency';
};
