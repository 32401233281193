import React from "react";
// Customizable Area Start
import { 
    Box,
    Grid,
    Container,
    Typography,
    Button,
    Card,
    CardContent,
    IconButton,
    styled,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    PaginationItem,
} from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { 
    activeMainDashboard, 
    activeMainReminder, 
    activePropertyMain, 
    activeMainFinancial, 
    keyMainChain, 
    activeKeyMain, 
    keasyIcon, 
    dashboardLandLogo, 
    financialMain, 
    propertyMain, 
    remindersMain, 
    bottomImageIcon,
} from "./assets";
import PopupMessage from "../../../components/src/PopupMessage.web";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Carousel from 'react-multi-carousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CustomLeftArrowProps } from './TenantDashboardController.web';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { 
    ArrowDropDown,
    ArrowDropUp,
  } from "@mui/icons-material";
import 'react-multi-carousel/lib/styles.css';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  
  const sliderImageUrl = [
    { url: "https://images.pexels.com/photos/1571459/pexels-photo-1571459.jpeg?cs=srgb&dl=pexels-fotoaibe-1571459.jpg&fm=jpg&_gl=1*2brgby*_ga*NTYwMDIyOTk1LjE3MTcxOTg2NDU.*_ga_8JE65Q40S6*MTcyODkyNTgyMS4zLjEuMTcyODkyNTg0Mi4wLjAuMA.." },
    { url: "https://images.pexels.com/photos/1643383/pexels-photo-1643383.jpeg?cs=srgb&dl=pexels-fotoaibe-1643383.jpg&fm=jpg&_gl=1*1byv6si*_ga*NTYwMDIyOTk1LjE3MTcxOTg2NDU.*_ga_8JE65Q40S6*MTcyODkyNTgyMS4zLjEuMTcyODkyNTg4Mi4wLjAuMA.." },
    { url: "https://images.pexels.com/photos/1457842/pexels-photo-1457842.jpeg?cs=srgb&dl=pexels-jvdm-1457842.jpg&fm=jpg&_gl=1*1byv6si*_ga*NTYwMDIyOTk1LjE3MTcxOTg2NDU.*_ga_8JE65Q40S6*MTcyODkyNTgyMS4zLjEuMTcyODkyNTg4Mi4wLjAuMA.." },
    { url: "https://images.pexels.com/photos/1648776/pexels-photo-1648776.jpeg?cs=srgb&dl=pexels-vika-glitter-392079-1648776.jpg&fm=jpg&_gl=1*6tydo*_ga*NTYwMDIyOTk1LjE3MTcxOTg2NDU.*_ga_8JE65Q40S6*MTcyODkyNTgyMS4zLjEuMTcyODkyNTg4Mi4wLjAuMA.." },
    { url: "https://images.pexels.com/photos/827518/pexels-photo-827518.jpeg?cs=srgb&dl=pexels-valeriya-827518.jpg&fm=jpg&_gl=1*6tydo*_ga*NTYwMDIyOTk1LjE3MTcxOTg2NDU.*_ga_8JE65Q40S6*MTcyODkyNTgyMS4zLjEuMTcyODkyNTg4Mi4wLjAuMA.." }
  ];
// Customizable Area End
import TenantDashboardController, {
    Props,
    configJSON
} from "./TenantDashboardController.web";

export default class TenantDashboard extends TenantDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    CustomLeftArrow = ( { onClick }:CustomLeftArrowProps ) => {
        return (
          <button 
            className="react-multiple-carousel__arrow" 
            onClick={() => {
                if (onClick) {
                onClick(); // Only call if onClick is defined
                }
            }}
            style={webStyles.carouselArrow}>
            <ChevronLeftIcon />
          </button>
        );
    };

    CustomRightArrow = ({ onClick }:CustomLeftArrowProps) => {
        return (
            <button 
                style={{...webStyles.carouselRightArrow, position: 'absolute'}} 
                onClick={() => {
                    if (onClick) {
                    onClick(); // Only call if onClick is defined
                    }
                }} >
                <ChevronRightIcon />
            </button>
        );
    };

    carouselComp = (images: { url: string }[]) => {
        return (
          <Carousel
            responsive={responsive}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            customLeftArrow={<this.CustomLeftArrow />}
            customRightArrow={<this.CustomRightArrow />}
            dotListClass="custom-dot-list-style"
            containerClass="react-multi-carousel-list"
          >
            {images.map((imageUrl, index) => (
              <div className="slider" key={index} style={webStyles.slider}>
                <img src={imageUrl.url} alt={`slider-item-${index}`} style={webStyles.sliderImage} />
              </div>
            ))}
          </Carousel>
        );
      };

    PaymentCard = (title: string, amount: string, date: string) => {
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: 3, borderRadius: 2, paddingRight: "50px", position: 'relative', marginLeft: "36px" }}>
        {/* Yellow Side Bar */}
        <Box sx={{ width: '6px', height: '100%', backgroundColor: '#FFC107', position: 'absolute', left: 0, borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} />
    
        {/* Content */}
        <CardContent sx={{ paddingLeft: '20px', paddingTop: '8px', paddingBottom: "8px !important" }}>
            <Typography sx={webStyles.regularTextValue}>
                {title}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: '600' }}>
                ${amount}
            </Typography>
            <Typography sx={{ ...webStyles.regularTextValue, color: date === 'Up to date' ? '#059669' : '#000000', minHeight: "24px" }}>
                {date}
            </Typography>
        </CardContent>
        </Card>
    );
    };

    renderSortableTableCell = (property: string, label: string) => {
    return (
        <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent="center">
            {label}
            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
                id={`ascendingIcon-${property}`}
                onClick={() => this.handleSortRequest(property, "asc")}
            >
                <ArrowDropUp />
            </CustomIconButtonUp>
            <CustomIconButtonDown
                id={`descendingIcon-${property}`}
                onClick={() => this.handleSortRequest(property, "desc")}
            >
                <ArrowDropDown />
            </CustomIconButtonDown>
            </Box>
        </Box>
        </CustomTableCell>
    );
    };

    renderStatusChip = (status: string) => {
    if (status.toUpperCase() === "CANCELED" || status.toUpperCase() === "FAILED") {
        return (
        <Chip
            label="CANCELED"
            sx={{
            backgroundColor: "#FEE2E2",
            color: "#DC2626",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
            borderRadius: "20px",
            }}
        />
        );
    } else if (status.toUpperCase() === "COMPLETED") {
        return (
        <Chip
            label="COMPLETED"
            sx={{
            backgroundColor: "#D1FAE5",
            color: "#059669",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
            borderRadius: "20px",
            }}
        />
        );
    } else if (status.toUpperCase() === "PENDING") {
        return (
        <Chip
            label="PENDING"
            sx={{
            backgroundColor: "#FEF3C7",
            color: "#D97706",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
            borderRadius: "20px",
            }}
        />
        );
    }
    };

    screenProps = {
        navigation: this.props.navigation,
        id: "BaselineReporting",
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentPage, rowsPerPage } = this.state;

        const paginatedRows = this.state.paymentHistory.slice(
            (currentPage - 1) * rowsPerPage,
            currentPage * rowsPerPage
        );
        const dashboardData = this.state.tenantDetails;
        const paymentData = this.state.paymentData;
        return (
            <Grid container direction="row" wrap="nowrap" 
                sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
            >
                <PopupMessage
                    open={this.state.popUpOpen}
                    type={this.state.popUpType}
                    message={this.state.popUpMessage}
                    handleClose={this.handlePopupMessageClose}
              />
            <Grid item sx={webStyles.navBarItem}>
                <NavigationSidebar
                    data-test-id={"tenantId"}
                    activeItem={this.state.activeTenantItem}
                    sidebarItems={[
                        { label: configJSON.dashboardLandloard, labelKey: configJSON.tenantDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.tenantDashboard },
                        { label: configJSON.serviceRequests, labelKey: configJSON.servicelabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.servicelabel },
                        { label: configJSON.lease, labelKey: configJSON.leaselabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.leaselabel },
                        { label: configJSON.paymentHistory, labelKey: configJSON.paymentHistorylabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.paymentHistorylabel },
                        { label: configJSON.chatLandloard, labelKey: configJSON.chatlandlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatTenantabel }
                    ]}
                    onClickSidebar={this.handleTenantitemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openTenantDrawer}
                    onClickDrawer={this.handleTenantDrawer}
                />
            </Grid>
            <Grid item sx={webStyles.mainContent}>
                <Container
                maxWidth={false}
                sx={{ position: "relative", paddingBottom: "100px", paddingLeft: "40px !important", paddingRight: "40px !important", minHeight: "100vh" }}
                >
                    <CustomNavbar 
                        {...this.screenProps}
                        showChatButton={true}   
                        showPropertiesButton={false} 
                    />
                    <Typography sx={webStyles.dashboardHeader} paragraph>
                            Dashboard
                        </Typography>

                        <Grid container sx={webStyles.mainContainer} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div style={webStyles.borderSection}>
                                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                                        <Typography sx={webStyles.subHeading} gutterBottom>
                                            LEASE OVERVIEW
                                        </Typography>
                                        <Button id="doYouNeedHelp" variant="text" sx={webStyles.headerButton}>
                                            <HelpOutlineIcon sx={webStyles.icon} />
                                            Do you need help?
                                        </Button>
                                    </div>
                                    

                                    <Grid container sx={{ padding: '10px' }}>
                                        <Grid item xs={12} mt={1}>
                                            <Typography sx={webStyles.subHeading} gutterBottom>
                                                {dashboardData.property} | {dashboardData.unit}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextLabel}>Tenant</Typography>
                                        </Grid>
                                        <Grid item xs={6} display={"flex"}>
                                        <Typography sx={webStyles.nameStyle}>{dashboardData.tenantName}</Typography>
                                        
                                        <Chip
                                            label="CURRENT"
                                            sx={{
                                            backgroundColor: "#D1FAE5",
                                            color: "#059669",
                                            fontSize: "12px",
                                            fontFamily: "Outfit",
                                            fontWeight: "700",
                                            borderRadius: "20px",
                                            marginLeft: "16px",
                                            height: "auto"
                                            }}
                                        />
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextLabel}>
                                            Lease start date
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextValue}>{dashboardData.leaseStartDate}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextLabel}>
                                            Lease end date
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextValue}>{dashboardData.leaseEndDate}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextLabel}>
                                            Address
                                        </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Typography sx={webStyles.regularTextValue}>{dashboardData.address}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ maxWidth: "90vw" }}>
                                        {this.carouselComp(sliderImageUrl)}
                                    </Box>
                                    
                                </div>
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <div style={webStyles.borderSection}>
                                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                                        <Typography sx={webStyles.subHeading} gutterBottom>
                                            LEASE DETAILS
                                        </Typography>
                                        <Button id="downloadInformation" variant="text" sx={webStyles.headerButton} onClick={this.downloadLeaseInfo}>
                                            <DownloadIcon sx={webStyles.icon} />
                                            Download lease information
                                        </Button>
                                    </div>

                                    <Grid container sx={{ padding: '10px' }}>

                                        <Grid item xs={12} mt={1}>
                                            <Typography sx={webStyles.subHeading} gutterBottom>
                                                Landlord information
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>Name</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.landlordName}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Landlord physical address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.landlordAddress}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Phone number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>({dashboardData.landlordPhone[0]}){" "+dashboardData.landlordPhone[1]}</Typography>
                                        </Grid>

                                        <Grid item xs={12} mt={3}>
                                            <Typography sx={webStyles.subHeading} gutterBottom>
                                                Tenant information
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>Name</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.tenantName}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.tenantEmail}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Phone number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>({dashboardData.tenantAreaCode}){" "+dashboardData.tenantPhone}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Number of occupants
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.noOfOccupants}</Typography>
                                        </Grid>

                                        <Grid item xs={12} mt={3}>
                                            <Typography sx={webStyles.subHeading} gutterBottom>
                                                Rental information
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>Date of first payment due</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.firstPaymentDue}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Pay period
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>Every {dashboardData.payPeriod} of the month</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Rent amount per month
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>${dashboardData.rent}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Security deposit
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>${dashboardData.deposit}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Payment method
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.method}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextLabel}>
                                                Collected by
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={webStyles.regularTextValue}>{dashboardData.collectedBy}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={webStyles.borderSection}>
                                    <div style={{ display: "flex", justifyContent: "flex-start"}}>
                                        <Typography sx={webStyles.subHeading} gutterBottom>
                                            PAYMENT HISTORY
                                        </Typography>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "48px"}}>
                                        <Box sx={{ display: "flex", flexDirection: {xs: "column", sm: "column", md: "row"} }}>
                                            <div>
                                                <Typography sx={webStyles.subHeading} gutterBottom>
                                                    Upcoming payment in
                                                </Typography>
                                                <Typography variant="h4" sx={{ fontWeight: 600, color: "#DAA520" }} gutterBottom>
                                                    {paymentData.upcoming_payment_date}
                                                </Typography>
                                                <Button data-test-id="transaction-btn" variant="text" sx={{...webStyles.headerButton, paddingLeft: 0 }} onClick={this.handleNewTransaction}>
                                                    <AddCircleOutlineIcon sx={webStyles.icon} />
                                                    New transaction
                                                </Button>
                                            </div>
                                            <Box sx={{ marginTop: {xs: "12px", sm: "12px", md: "0"} }}>
                                                {this.PaymentCard("Last payment", paymentData.last_payment, paymentData.last_transaction_date ?? " ")}
                                            </Box>
                                            <Box sx={{ marginTop: {xs: "12px", sm: "12px", md: "0"} }}>
                                                {this.PaymentCard("In debt", paymentData.pending_amount, paymentData.pending_amount === "0.0" ? "Up to date" : " ")}
                                            </Box>
                                        </Box>
                                    </div>
                                    <TableContainer component={Paper}>
                                        <Table>
                                        <TableHead>
                                            <TableRow>
                                            <CustomTableCell key="id" align="center">
                                                Reference
                                            </CustomTableCell>
                                            {this.renderSortableTableCell("transaction_date", "Date")}
                                            {this.renderSortableTableCell("amount", "Amount")}
                                            {this.renderSortableTableCell("ledger_type", "Description")}
                                            <CustomTableCell key="details" align="center">
                                                Details
                                            </CustomTableCell>
                                            {this.renderSortableTableCell("transaction_status", "Transaction status")}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paginatedRows.length === 0 ? (
                                                <TableRow data-test-id="noDataRow">
                                                    <TableCell colSpan={10} align="center" style={{ fontSize: "14px", color: "#A3978F" }}>
                                                    No transactions found
                                                    </TableCell>
                                                </TableRow>
                                                ) : (paginatedRows.map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${row.id}`;

                                            return (
                                                <>
                                                <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">#{row.attributes.id}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">{row.attributes.transaction_date}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography align="center"sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px', color: '#059669' }}>
                                                        ${row.attributes.amount}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography align="center" sx={{ fontFamily: 'Outfit', fontWeight: 600, fontSize: '12px' }} >
                                                        {row.attributes.ledger_type}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">
                                                        {row.attributes.details}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" >
                                                        {this.renderStatusChip(row.attributes.transaction_status)}
                                                    </TableCell>
                                                </TableRow>
                                                </>
                                            );
                                            }))}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                        {paginatedRows.length > 0 && <Box sx={webStyles.paginationContainer}>
                                            <Box></Box>
                                            <Pagination
                                                data-test-id="pagination"
                                                count={Math.ceil(this.state.paymentHistory.length / rowsPerPage)}
                                                page={currentPage}
                                                onChange={this.handleChangePage}
                                                renderItem={(item) => (
                                                <PaginationItem
                                                    {...item}
                                                    sx={{
                                                    "&.Mui-selected": {
                                                        color: "#CC9200",
                                                        backgroundColor: "unset",
                                                    },
                                                    }}
                                                />
                                                )}
                                            />
                                            <Typography sx={{ marginRight: "10%", fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px', color: '#70645C' }}>
                                                {this.state.paymentHistory.length === 0
                                                ? "0 results"
                                                : `${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                                                currentPage * rowsPerPage,
                                                this.state.paymentHistory.length
                                                )} of ${this.state.paymentHistory.length} results`}
                                            </Typography>
                                    </Box>}

                                </div>
                            </Grid>
                        </Grid>
                        <img
                            src={bottomImageIcon}
                            alt="Description"
                            style={{
                                position: "absolute",
                                bottom: "-15px",
                                right: "0"
                            }}
                        />
                </Container>
                </Grid>
            </Grid>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {
    dashboardHeader: {
        fontSize: "24px",
        fontFamily: "Outfit",
        fontWeight: 700,
        color: "#000000",
        marginBottom: "0",
    },
    navBarItem: {
      position: "fixed",
      top: 0,
      left: 0,
      height: "100%",
      overflowY: "auto",
      zIndex: 1000,
      "@media(max-width: 992px)": {
        position: "unset",
        height: "auto",
        overflowY: "hidden",
        border: "none",
      },
      scrollbarWidth: "none", 
      msOverflowStyle: "none",
      '&::-webkit-scrollbar': {
        display: 'none',
      }
    },
    mainContainer: {
        paddingTop: "20px",
        paddingBottom: "20px",
        "@media (max-width: 600px)": {
          flexDirection: "column",
        },
    },
    mainContent: {
        marginLeft: 0,
        "@media (min-width: 992px)": {
            borderLeft: "1px solid rgb(208,203,199)",
            marginLeft: "125px",
            width: 'calc(100% - 125px)',
        }
      },
    borderSection:{
        border: "2px solid #FFD466",
        borderRadius: "8px",
        borderBottomRightRadius: "30px",
        padding: "34px 20px",
        boxShadow: "unset",
    },
    carouselArrow: {
        color: 'black',
        backgroundColor: '#FFC123',
        cursor: 'pointer',
      },
    carouselRightArrow: {
        color: 'black',
        backgroundColor: '#FFC123',
        cursor: 'pointer',
        position: 'absolute',
        outline: 0,
        transition: 'all .5s',
        borderRadius: '35px',
        zIndex: 1000,
        border: 0,
        minWidth: '43px',
        minHeight: '43px',
        opacity: 1,
        right: 'calc(0% + 1px)',
    },
    modal_header: {
        display: "flex",
        justifyContent: "space-between",
        p: 2,
    },
    headerButton: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#CC9200",
        textTransform: "initial",
        paddingTop: 0,
    },
    icon: {
        marginRight: "10px",
        color: "#CC9200",
    },
    subHeading: {
        fontSize: "16px",
        fontFamily: "Outfit",
        fontWeight: 700,
    },
    regularTextLabel: {
        fontSize: "14px",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#BAB1AB",
        marginRight: "15px",
    },
    regularTextValue: {
        fontSize: "14px",
        fontFamily: "Outfit",
        fontWeight: 400,
        wordBreak: 'break-word',
    },
    nameStyle: {
        fontSize: "14px",
        fontFamily: "Outfit",
        fontWeight: 600,
        wordBreak: 'break-word',
    },
    slider: {
        margin: '0 20px',
        overflow: 'hidden',
        padding: '2rem 0',
      },
      sliderImage: {
        width: '100%',
        borderRadius: '10px',
        height: '235px'
      },
      reactMultiCarouselList: {
        padding: '0rem 0 2rem 0',
      },
      customDotListStyle: {
        button: {
          border: 'none',
          background: 'rgb(255, 68, 68)',
        },
      },
      activeDotButton: {
        background: 'rgb(255, 68, 68)',
      },
      paginationContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0",
      },
}

const CustomTableCell = styled(TableCell)({
    color: "#A3978F",
    textAlign: "center",
    fontFamily: 'Outfit',
    fontSize: "12px",
    fontWeight: 700,
    padding: "0px 10px 0px 10px !important",
    margin: "auto",
});
  
const CustomIconButtonUp = styled(IconButton)({
    cursor: "pointer",
    height: "12px",
    width: "12px",
});
  
const CustomIconButtonDown = styled(IconButton)({
    cursor: "pointer",
    height: "12px",
    width: "12px"
});
// Customizable Area End
