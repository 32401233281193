import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { AlertColor } from "@mui/material";
import { FormEvent } from "react";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import storage from "../../../framework/src/StorageProvider.web";
interface FileWithPreview extends File {
  preview: string;
}

interface WorkOrderAudit {
  date: string;
  updated_by: string ;
  description: string | null;
  status: string;
}

export interface WorkOrder {
  id: number;
  date: string;
  requested_by: string;
  property: string;
  category: string;
  work_needed: string;
  description: string;
  cost: string;
  status: string;
  work_order_audits: WorkOrderAudit[];
  documents: any[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activeVendorItem: string;
  openVendorDrawer: boolean;
  startDate: any;
  endDate: any;
  searchInput: string;
  searchArray: string[];
  workOrders: WorkOrder[];
  selectedOrders: number[];
  sendInvoiceModal: boolean;
  scheduleDateModal: boolean;
  modalWorkOrderArray: number[];
  currentWorkOrderIndex: number;
  currentModalWorkOrder: any;
  invoiceDate: Date | null;
  dueDate: Date | null;
  price: string;
  taxes: string;
  receipts: FileWithPreview[];
  errors: {
    price: boolean;
    taxes: boolean;
    receipts: boolean;
  }
  openDetailRow: number | null;
  anchorNewElement: { [key: number]: HTMLElement | null };
  currentPage: number;
  rowsPerPage: number;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  ownerToken: string;
  startDateOpen: boolean;
  endDateOpen: boolean;
  projectStartDate: Date | null;
  projectCompletionDate: Date | null;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TasksController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWorkOrdersApiCallId: string = "";
  scheduleDateApiCallId: string = "";
  sendInvoiceApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeVendorItem: "Tasks",
      openVendorDrawer: false,
      startDate: null,
      endDate: null,
      searchInput: "",
      searchArray: [],
      workOrders: [],
      selectedOrders: [],
      sendInvoiceModal: false,
      scheduleDateModal: false,
      modalWorkOrderArray: [],
      currentWorkOrderIndex: 0,
      currentModalWorkOrder: {},
      invoiceDate: null,
      dueDate: null,
      price: "",
      taxes: "",
      receipts: [],
      errors: {
        price: false,
        taxes: false,
        receipts: false,
      },
      openDetailRow: null,
      anchorNewElement: {},
      currentPage: 1,
      rowsPerPage: 12,
      messageOpen: false,
      messageType: "success",
      message: "",
      startDateOpen:false,
      endDateOpen:false,
      projectStartDate: null,
      projectCompletionDate: null,
      ownerToken:"",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getWorkOrders();
    const ownerToken =  await getStorageData("owner");
    if (ownerToken) {
      await storage.remove("owner");
      this.setState(prevState => ({
        searchArray: [ownerToken, ...prevState.searchArray]  
      }), () => {
        this.handleFilter();
      });
    }
  }

  navigateToTaskList = () => {
    this.props.navigation.navigate("TaskList");
  };

  navigateToTask = () => {
    this.props.navigation.navigate("Task");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleGetWorkOrdersResponse(message);
      this.handleScheduleDateResponse(message);
      this.handleSendInvoiceResponse(message);
    }
  };

  getWorkOrders = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkOrdersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkOrdersApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onSendInvoiceSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      const header = {
        token: localStorage.getItem("authToken"),
      };
  
      const formData = new FormData();
  
      formData.append(
        "invoice[vendor_work_order_ids][]",
        this.state.currentModalWorkOrder.id
      );
  
      formData.append(
        "invoice[ledger_type]",
        "vendor payment"
      );
  
      formData.append(
        "invoice[invoice_date]",
        this.state.invoiceDate ? this.formatSelectedDate(this.state.invoiceDate) : ""
      );
  
      formData.append(
        "invoice[due_date]",
        this.state.dueDate ? this.formatSelectedDate(this.state.dueDate) : ""
      );
  
      formData.append(
        "invoice[price]",
        this.state.price
      );
  
      formData.append(
        "invoice[taxes]",
        this.state.taxes
      );
  
      if(this.state.receipts.length) {
        this.state.receipts.forEach((file) => {
          formData.append("invoice[receipts][]", file as Blob);
        });
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.sendInvoiceApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendInvoiceApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  onScheduleDateSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formData = new FormData();

    formData.append(
      "vendor_work_order[vendor_work_order_ids][]",
      this.state.currentModalWorkOrder.id
    );

    formData.append(
      "vendor_work_order[project_start_date]",
      this.state.projectStartDate ? this.formatSelectedDate(this.state.projectStartDate).split("-").reverse().join("-") : ""
    );

    formData.append(
      "vendor_work_order[project_completion_date]",
      this.state.projectCompletionDate ? this.formatSelectedDate(this.state.projectCompletionDate).split("-").reverse().join("-") : ""
    );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scheduleDateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleDateApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetWorkOrdersResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getWorkOrdersApiCallId && responseJson?.data) {
      let workOrders: WorkOrder[] = [];
      if(responseJson?.data?.length){
        workOrders = responseJson.data.map((order: any) => {
          return {
            id: order.attributes.id,
            date: this.formatDate(order.attributes.created_at),
            requested_by: order.attributes.requested_by,
            property: order.attributes.property.property_name + " - " + order.attributes.unit.name,
            category: order.attributes.sub_category?.sub_category ?? "",
            work_needed: order.attributes.work_needed,
            description: order.attributes.description ?? "",
            cost: order.attributes.cost ?? "--",
            status: order.attributes.status,
            work_order_audits: order.attributes.work_order_audits,
            documents: order.attributes.documents
          };
        });
      }
      this.setState({ workOrders: workOrders });
    } else if(responseJson?.errors) {
      this.setState({
        messageOpen: true,
        messageType: "error",
        message: responseJson?.errors[0]?.token ? "Session Expired, Please Log in again." : responseJson?.errors
      });
    }
  };

  handleSendInvoiceResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.sendInvoiceApiCallId && responseJson?.invoices?.length > 0) {
      this.setState({
        messageOpen: true,
        messageType: "success",
        message: "Form submitted successfully."
      });
      this.handleModalClose('sendInvoice');

    } else if(responseJson?.errors) {
      this.setState({
        messageOpen: true,
        messageType: "error",
        message: responseJson?.errors[0]?.token ? "Session Expired, Please Log in again." : responseJson?.errors
      });
    }
  };

  handleScheduleDateResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.scheduleDateApiCallId && responseJson?.updated_work_orders?.length) {
      this.setState({
        messageOpen: true,
        messageType: "success",
        message: "Form submitted successfully."
      });
      this.handleModalClose('scheduleDate');
      
    } else if(responseJson?.errors) {
      this.setState({
        messageOpen: true,
        messageType: "error",
        message: responseJson?.errors[0]?.token ? "Session Expired, Please Log in again." : responseJson?.errors
      });
    }
  };

  onVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  onVendorDrawer = () => {
      this.setState({
          openVendorDrawer: !this.state.openVendorDrawer
      });
  };

  handleWorkOrderMenu = () => {
    const { activeVendorItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeVendorItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  formatDate = (dateString: string) => {
    if(dateString){
      const dateObject = new Date(dateString);

      const day = String(dateObject.getUTCDate()).padStart(2, '0');
      const month = String(dateObject.toLocaleString('default', { month: 'short' }));
      const year = dateObject.getUTCFullYear();

      return `${month} ${day} ${year}`;
    } else {
      return "No date selected";
    }
  };

  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  handleCheckBoxClick = (id: number) => {
    const selectedIndex = this.state.selectedOrders.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedOrders.slice(1));
    } else if (selectedIndex === this.state.selectedOrders.length - 1) {
      newSelected = newSelected.concat(this.state.selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedOrders.slice(0, selectedIndex),
        this.state.selectedOrders.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedOrders: newSelected});
  };

  handleSortRequest = (property: keyof WorkOrder, order: string) => {
    const workOrdersCopy = [...this.state.workOrders];
    workOrdersCopy.sort((a, b) => {
      if (a[property] < b[property]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    this.setState({ workOrders: workOrdersCopy});
  };

  isSelected = (id: number) => this.state.selectedOrders.indexOf(id) !== -1;

  convertNumberToWords = (n: number) => {
    if (n < 0)
      return false;
  
    // Arrays to hold words for single-digit, double-digit, and below-hundred numbers
    const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    const double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    
    if (n === 0) return 'Zero';
    
    // Recursive function to translate the number into words
    function translate(n: number) {
        let word = "";
        if (n < 10) {
            word = single_digit[n] + ' ';
        } else if (n < 20) {
            word = double_digit[n - 10] + ' ';
        } else if (n < 100) {
            let rem = translate(n % 10);
            word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
        } else if (n < 1000) {
            word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
        }
        return word;
    }

    // Get the result by translating the given number
    let result = translate(n);
    return result.trim();
  }

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "price") {
      this.validatePrice(value);
    } else if (name === "taxes") {
      this.validateTaxes(value);
    }
  };

  validatePrice = (price: string) => {
    const priceRegex = /^\d{1,10}(\.\d{0,2})?$/;
    if (!priceRegex.test(price)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          price: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          price: false,
        },
      }));
    }
  };

  validateTaxes = (tax: string) => {
    const taxRegex = /^\d{1,10}(\.\d{0,2})?$/;
    if (!taxRegex.test(tax)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxes: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxes: false,
        },
      }));
    }
  }

  getHelperText = (field: string) => {
    const { errors } = this.state;

    if(field === "price" && errors.price) {
        return "Please enter a valid price.";
    }
    if(field === "taxes" && errors.taxes) {
      return "Please enter a valid tax cost.";
    }
    return null;
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateFiles(name);
    });
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      
      const newSearch = this.state.searchInput
      .split(",")
      .map((param) => param.trim())
      .filter((param) => param !== "");

      // Combine the new trades with the existing trades
      const combinedSearch = [...this.state.searchArray, ...newSearch];

      // Remove duplicate values
      const uniqueSearch = combinedSearch.filter(
        (item, index) => combinedSearch.indexOf(item) === index
      );

      this.setState({ searchArray: uniqueSearch, searchInput: "" },() => {
        this.handleFilter();
      });
    }
  };

  handleClearAll = () => {
    this.setState({ searchArray: [], searchInput: "" },() => {
        this.handleFilter();
      });
  }

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.searchArray.filter(
      (item) => item !== trade
    );
    this.setState({ searchArray: updatedTrade },() => {
        this.handleFilter();
      });
  };

  validateField = (field: string) => {
    if (field === "receipts") {
      return this.state.errors.receipts;
    } else {
      return false;
    }
  };

  validateFiles = (name: string) => {
    if(name === "receipts") {
      if (this.state.receipts.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            receipts: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            receipts: false,
          },
        }));
      }
    }
  };
  
  handleRowClick = (index: number) => {
    this.setState((prevState) => ({
      openDetailRow: prevState.openDetailRow === index ? null : index
    }));
  };

  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    console.log(eventIcon);
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: null
      },
    });
  };

  handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage });
  };

  handleSendInvoiceClick = (orderIdArray: number[]) => {
    if(orderIdArray.length) {
      const currentWorkOrder = this.state.workOrders.find(order => order.id === orderIdArray[0]);
      this.setState({ sendInvoiceModal: true, modalWorkOrderArray: orderIdArray, currentWorkOrderIndex: 0, currentModalWorkOrder: currentWorkOrder });
    }
  };

  handleScheduleDateClick = (orderIdArray: number[]) => {
    if(orderIdArray.length) {
      const currentWorkOrder = this.state.workOrders.find(order => order.id === orderIdArray[0]);
      this.setState({ scheduleDateModal: true, modalWorkOrderArray: orderIdArray, currentWorkOrderIndex: 0, currentModalWorkOrder: currentWorkOrder });
    }
  };

  handleModalClose = (type: string) => {
    if(type === 'sendInvoice') {
      this.setState({ sendInvoiceModal: false, invoiceDate: null, dueDate: null, price: "", taxes: "", receipts: [] });
      if (this.state.currentWorkOrderIndex < this.state.modalWorkOrderArray.length - 1) {
        const currentWorkOrder = this.state.workOrders.find(order => order.id === this.state.modalWorkOrderArray[this.state.currentWorkOrderIndex+1]);
        this.setState((prevState) => ({
          currentWorkOrderIndex: prevState.currentWorkOrderIndex + 1,
          currentModalWorkOrder: currentWorkOrder
        }), ()=>{
          this.setState({ sendInvoiceModal: true })
        })
      }
    }
    else {
      this.setState({ scheduleDateModal: false, projectStartDate: null, projectCompletionDate: null });
      if (this.state.currentWorkOrderIndex < this.state.modalWorkOrderArray.length - 1) {
        const currentWorkOrder = this.state.workOrders.find(order => order.id === this.state.modalWorkOrderArray[this.state.currentWorkOrderIndex+1]);
        this.setState((prevState) => ({
          currentWorkOrderIndex: prevState.currentWorkOrderIndex + 1,
          currentModalWorkOrder: currentWorkOrder
        }), ()=>{
          this.setState({ scheduleDateModal: true })
        })
      }
    }
  };

  validateForm = () => {
    this.validateFiles("receipts");
    if (
      !this.state.receipts.length ||
      this.state.errors.price ||
      this.state.errors.taxes
    ) {
      return false;
    }
    return true
  };

  handleOpen = (field: string) => {
    if (field === "startDate") {
        this.setState({ startDateOpen: true, endDateOpen: false });
    } else if (field === "endDate") {
        this.setState({ startDateOpen: false, endDateOpen: true });
    }
  };

  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };

  handleFilter = async() => {
    const token = localStorage.getItem("authToken");
    const header = {
      token: token,
     'Content-Type': 'application/json',
    };
    let getWorkOrdersApiEndPoint = configJSON.getWorkOrdersApiEndPoint + '?';
    const { startDate, endDate, searchArray } = this.state;

    if (startDate) {
      getWorkOrdersApiEndPoint = getWorkOrdersApiEndPoint + 'start_date=' + startDate.format('YYYY-MM-DD') + '&';
    }

    if (endDate) {
      getWorkOrdersApiEndPoint = getWorkOrdersApiEndPoint + 'end_date=' + endDate.format('YYYY-MM-DD') + '&';
    }

    if(searchArray.length) {
      for(let query in searchArray){
        getWorkOrdersApiEndPoint = getWorkOrdersApiEndPoint + 'query=' + searchArray[query] + '&';
      }
    }
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkOrdersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getWorkOrdersApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatSelectedDate = (date: any) => {

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const localDate = `${year}-${month}-${day}`;
    
    return localDate;
  };

  // Customizable Area End
}
