import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

export interface WorkOrder {
  id: number;
  location: string;
  category: string;
  work_needed: string;
  status: string;
}

export interface PreferredVendors {
  preferred_vendors: Array<PreferredVendorDetails>;
  meta: {
    total_pages: number;
    total_count: number
    current_page: number
  }
}

export interface PreferredVendorDetails {
  id: number;
  name: string;
  email: string;
  vendor_type: string;
  phone_number: string;
  company_name: string;
  working_hours: {
    mon_fri: string;
    sat: string;
    sun: string;
  },
  min_cost: number,
  profile_pic: string;
}

export interface APIPayloadType {
  contentType?: string;
  endPoint: string;
  method: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface WorkOrderDetails {
  landlord_work_orders: Array<WorkOrder>;
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
  }
}

export interface SortDirection {
  sortsColumn: string;
  sortsDirection: 'asc' | 'desc';
}

export interface RemindersDetails {
  id: number;
  location: string;
  category: string;
  due_date: string;
  status: string;
  assignee: string;
}

export interface DelinquencyData {
  property_name: string;
  paid_count: number;
  payment_0_to_30_days_late: number;
  payment_over_0_to_30_days_late: number;
  vacant_count: number;
}

export interface Reminders {
  reminders: Array<RemindersDetails>;
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
  }
}

export interface Deliquency {
  data: Array<DelinquencyData>
  total: {
    total_count: number;
    paid_count: number;
    payment_0_to_30_days_late: number;
    payment_over_0_to_30_days_late: number;
  }
}

export interface ExistingList {
  vendors: Array<ExistingVendorDetails>;
}

export interface ExistingVendorDetails {
  id: number;
  name: string;
  vendor_type: string;
  status: string;
  email_address: string;
  company_name: string;
  phone_number: string;
}

export interface DisabledVendorsStates {
  [vendorId: number]: boolean;
}

export interface InvalidResponseType {
  errors: string;
}

export interface RenewalOutLook {
  status: number;
  less_than_30: number;
  between_30_to_60: number;
  between_60_to_90: number;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  activeMainItem: string;
  openLandlordDrawer: boolean;
  workOrders: Array<WorkOrder>;
  sortingState: SortDirection;
  reminders: Array<RemindersDetails>;
  delinquencyData: Array<DelinquencyData>;
  totals: {
    paid: number;
    late30: number;
    late60: number;
    late60plus: number;
  };
  totalWorkOrder: number;
  currentWorks: number;
  countWorks: number;
  valueOfKey: Object;
  preferredVendor: Array<PreferredVendorDetails>;
  messagesForPopup: string;
  messagesType: 'success' | 'error' | 'warning' | 'info'
  messagesPopup: boolean;
  currentReminder: number;
  countReminder: number;
  totalReminder: number;
  searchWorkOrder: string;
  reminderSearch: string;
  searchVendor: string;
  vendorType: Array<string>;
  origionalsVendor: Array<PreferredVendorDetails>;
  anchorEl: null | HTMLElement;
  openTypeFilter: boolean;
  newVendorType: string;
  anchorsElement: { [key: number]: HTMLElement | null };
  workOrderLength: boolean;
  reminderLength: boolean;
  preferredVendorLength: boolean;
  deliquencyLength: boolean;
  preferredVendorsModal: boolean;
  existingOldVendors: Array<ExistingVendorDetails>;
  disabledVendorsStates: DisabledVendorsStates;
  total: {
    total_count: number;
    paid_count: number;
    payment_0_to_30_days_late: number;
    payment_over_0_to_30_days_late: number;
  }
  renewalOutLook: RenewalOutLook; 
  currentVendor: number;
  totalVendor: number;
  countVendor: number;
  searchErrorWorkOrder: string | null;
  searchErrorReminder: string | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getLandlordWorkOrder: string = "";
  getPreferredVendorAPiCall: string = "";
  getRemindersAPiCall: string = "";
  getVendorsTypesApiCallId: string = "";
  updateRemindersStatus: string = "";
  deliquencyReportApiCallId: string = "";
  getOldVendoApiCallId: string= "";
  postAddExistingVendorApiCall: string = "";
  getRenewalLookApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      activeMainItem: webConfigJSON.dashboardLandlabel,
      openLandlordDrawer: false,
      workOrders: [],
      sortingState: {
        sortsColumn: '',
        sortsDirection: 'asc',
      },
      reminders: [],
      delinquencyData: [],
      totals: {
        paid: 0,
        late30: 0,
        late60: 0,
        late60plus: 0
      },
      totalWorkOrder: 0,
      currentWorks: 1,
      countWorks: 0,
      valueOfKey: {},
      preferredVendor: [],
      messagesForPopup: "",
      messagesType: "success",
      messagesPopup: false,
      currentReminder: 1,
      countReminder: 0,
      totalReminder: 0,
      searchWorkOrder: "",
      reminderSearch: "",
      searchVendor: "",
      vendorType: [],
      origionalsVendor: [],
      anchorEl: null,
      openTypeFilter: false,
      newVendorType: "",
      anchorsElement: {},
      workOrderLength: false,
      reminderLength: false,
      preferredVendorLength: false,
      deliquencyLength: false,
      preferredVendorsModal: false,
      existingOldVendors: [],
      disabledVendorsStates: {},
      total: {
        total_count: 0,
        paid_count: 0,
        payment_0_to_30_days_late: 0,
        payment_over_0_to_30_days_late: 0
      },
      renewalOutLook: {
        status: 0,
        less_than_30: 0,
        between_30_to_60: 0,
        between_60_to_90: 0
      },
      currentVendor: 1,
      totalVendor: 0,
      countVendor: 0,
      searchErrorWorkOrder: null,
      searchErrorReminder: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getWorkOrders(this.state.currentWorks);
    this.getPreferredVendor(this.state.currentVendor);
    this.getReminders(this.state.currentReminder);
    this.getPreferredVendorTypes();
    this.getDeliquencyReport();
    this.getRenewalOutlook();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
      this.getExistingDetails();
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
      if (!webResponseJson.errors && webResponseJson) {
        this.apiSuccessCallBacks(webApiRequestCallId, webResponseJson);
      } else if (webResponseJson && webResponseJson.errors) {
        this.apiFailCallBack(webApiRequestCallId, webResponseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleDashboardMenu();
    });
  };

  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer
    });
  };

  handleDashboardMenu = () => {
    const { activeMainItem } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleDashboardRoutes = (status: string) => {
    this.setState({
      activeMainItem: status
    }, () => {
      this.handleDashboardMenu();
    })
  };

  dashboardApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body, type } = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let webRequestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    webRequestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    webRequestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    webRequestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    webRequestsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );

    runEngine.sendMessage(webRequestsMessage.id, webRequestsMessage);
    return webRequestsMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: WorkOrderDetails & PreferredVendors & Reminders & Deliquency & ExistingList & RenewalOutLook) => {
    if (apiRequestCallID === this.getLandlordWorkOrder) {
        this.setState({
          workOrders: responseJson.landlord_work_orders,
          totalWorkOrder: responseJson.meta.total_pages,
          currentWorks: responseJson.meta.current_page,
          countWorks: responseJson.meta.total_count,
          workOrderLength: true,
          searchErrorWorkOrder: null
        }, () => {
          this.handleWorkOrderPagination();
        });
          }

    if (apiRequestCallID === this.getPreferredVendorAPiCall) {
      this.setState({
        preferredVendor: responseJson.preferred_vendors,
        preferredVendorLength: true,
        totalVendor: responseJson.meta.total_pages,
        countVendor: responseJson.meta.total_count,
        currentVendor: responseJson.meta.current_page,
      },()=>{
        this.handleVendorPagination();
      });
    }

    if (apiRequestCallID === this.getRemindersAPiCall) {
      this.setState({
        reminders: responseJson.reminders,
        totalReminder: responseJson.meta.total_pages,
        currentReminder: responseJson.meta.current_page,
        countReminder: responseJson.meta.total_count,
        reminderLength: true,
        searchErrorReminder: null
      });
    }
    
    if (apiRequestCallID === this.getVendorsTypesApiCallId) {
      let vendorInfo = responseJson.preferred_vendors;
      const getVendorTypes = (vendors: PreferredVendorDetails[]): string[] => {
        return vendors.map((vendor: PreferredVendorDetails) => vendor.vendor_type);
      };
      const vendorTypesArray = getVendorTypes(vendorInfo);
      const uniqueVendorTypesArray = Array.from(
        new Set(vendorTypesArray.filter(vendorType => vendorType !== null && vendorType.trim() !== ''))
      );
      this.setState({
        origionalsVendor: responseJson.preferred_vendors,
        vendorType: uniqueVendorTypesArray
      });
    }

    if (apiRequestCallID === this.updateRemindersStatus) {
      this.setState({
        messagesForPopup: "Status updated successfully for selected reminder",
        messagesType: "success",
        messagesPopup: true
      }, () => {
        this.getReminders(this.state.currentReminder);
      })
    }

    if (apiRequestCallID === this.deliquencyReportApiCallId) {
      this.setState({
        delinquencyData: responseJson.data,
        total:{
          total_count: responseJson.total.total_count,
          paid_count: responseJson.total.paid_count,
          payment_0_to_30_days_late: responseJson.total.payment_0_to_30_days_late,
          payment_over_0_to_30_days_late: responseJson.total.payment_over_0_to_30_days_late
        },
        deliquencyLength: true
      });
    }

    if (apiRequestCallID === this.getOldVendoApiCallId) {
      this.setState({
        existingOldVendors: responseJson.vendors
      });
    }

    if (apiRequestCallID === this.postAddExistingVendorApiCall) {
      this.setState({
        messagesType: "success",
        messagesPopup: true,
        messagesForPopup: "The vendor has been successfully added to your preferred vendor list."
      }, () => {
        this.getPreferredVendor(this.state.currentVendor);
      });
    }

    if (apiRequestCallID === this.getRenewalLookApiCallId) {
      this.setState({
        renewalOutLook: responseJson
      });
    }
  };

  getWorkOrders = async (page: number) => {
    this.getLandlordWorkOrder = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: `${webConfigJSON.dashboardWorkOrderEndPoint}${page}&search_query=${this.state.searchWorkOrder}`
    });
  };

  statusHandler = (status: string) => {
    let styleObjPriority = {
      awaiting: {
        background: "#FEF3C7",
        color: "#D97706",
        borderColor: "#FEF3C7"
      },
      pay_bill: {
        color: "#DC2626",
        borderColor: "#FEE2E2",
        background: "#FEE2E2",
      },
      vendor: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      completed: {
        color: "#70645C",
        background: "#E8E5E3",
        borderColor: "#ADD8E6"
      },
      declined: {
        color: "#00008B",
        background: "#ADD8E6",
        borderColor: "#ADD8E6"
      }
    }
    switch (status) {
      case "WAITING LANDLORD APPROVAL":
        return styleObjPriority.awaiting
      case "PAY BILL":
        return styleObjPriority.pay_bill
      case "DECLINED":
        return styleObjPriority.declined
      case "ASSIGNED TO VENDOR":
        return styleObjPriority.vendor
      case "COMPLETED":
      case "WORK COMPLETED":
        return styleObjPriority.completed
      default:
        return styleObjPriority.awaiting
    }
  };

  handleLargeDetails = (detailsSize: string) => {
    if(detailsSize && detailsSize.trim() !== ""){
      return detailsSize.length > 10 ? detailsSize.substring(0, 7) + "..." : detailsSize;
    }
    return "-";
  };

  handleChangeWorkPagination = (value: object, page: number) => {
    this.setState({
      currentWorks: page,
      valueOfKey: value
    }, () => {
      this.getWorkOrders(page);
    });
  };

  handleWorkOrderPagination = () => {
    const workDetails = this.state.workOrders;
    if (workDetails.length < 1) {
      this.setState(prevState => ({
        currentWorks: Math.max(1, prevState.currentWorks - 1)
      }));
    }
  };

  getPreferredVendor = async (vendorPage: number) => {
    this.getPreferredVendorAPiCall = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: `${webConfigJSON.dashboardVendorEndPoint}${8}&page=${vendorPage}&search_query=${this.state.searchVendor}&vendor_type=${this.state.newVendorType}`
    });
  };

  handleBlankDetail = (details: number | string) => {
    return details ? details : webConfigJSON.blankDetails
  };

  getReminders = async (reminderPage: number) => {
    this.getRemindersAPiCall = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: `${webConfigJSON.reminderEndPoint}${reminderPage}&search_query=${this.state.reminderSearch}`
    });
  };

  handleSortRequest = (property: string, direction: 'asc' | 'desc') => {
    this.setState((prevState) => ({
      sortingState: {
        ...prevState.sortingState,
        sortsColumn: property,
        sortsDirection: direction,
      },
    }));
  };

  sortRemindersData = (newData: Array<RemindersDetails>) => {
    const { sortsColumn, sortsDirection } = this.state.sortingState;
    return [...newData].sort((sortingA: RemindersDetails, sortingB: RemindersDetails) => {
      const aValue = sortingA[sortsColumn as keyof RemindersDetails];
      const bValue = sortingB[sortsColumn as keyof RemindersDetails];
      if (bValue === null || bValue === undefined) return -1;
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * (sortsDirection === "asc" ? 1 : -1);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return (aValue - bValue) * (sortsDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  handleColoring = (colors: string) => {
    return colors.includes("Ago") ? "red" : "black"
  };

  handleChangeReminderPagination = (value: object, page: number) => {
    this.setState({
      currentReminder: page,
      valueOfKey: value
    }, () => {
      this.getReminders(page);
    });
  };

  handleReminderPagination = () => {
    const remindersDetails = this.state.reminders;
    if (remindersDetails.length < 1) {
      this.setState(prevState => ({
        currentReminder: Math.max(1, prevState.currentReminder - 1)
      }));
    }
  };

  handleOpenDashboardWork = async (openWorkId: number, openDetails: string) => {
    await storage.set("openWorkId", openWorkId)
    await storage.set("openStatus", "open")
    const toDashOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toDashOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      openDetails
    );
    toDashOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toDashOpenWork);
  };

  handleWorkSearchBar = (searchEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchWorkOrder = searchEvent.target.value;
    const maximumLength = 30;
    if (searchWorkOrder.length > maximumLength) {
      this.setState({
        messagesForPopup: webConfigJSON.searchingError,
        messagesType: "error",
        messagesPopup: true,
      })
    } else {
      this.setState({
        searchWorkOrder: searchWorkOrder
      }, () => {
        this.getWorkOrders(this.state.currentWorks);
      });
    };

  };

  handleReminderSearchBar = (reminderEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchReminder = reminderEvent.target.value;
    const maximumLength = 30;
    if (searchReminder.length > maximumLength) {
      this.setState({
        messagesType: "error",
        messagesForPopup: webConfigJSON.searchingError,
        messagesPopup: true,
      })
    } else {
      this.setState({
        reminderSearch: searchReminder
      }, () => {
        this.getReminders(this.state.currentReminder);
      });
    };
  };

  handleVendorSearchBar = (vendorEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchVendor = vendorEvent.target.value;
    const maximumLength = 30;
    if (searchVendor.length > maximumLength) {
      this.setState({
        messagesType: "error",
        messagesPopup: true,
        messagesForPopup: webConfigJSON.searchingError
      })
    } else {
      this.setState({
        searchVendor: searchVendor
      }, () => {
        this.getPreferredVendor(this.state.currentVendor);
      });
    };
  };

  getPreferredVendorTypes = async () => {
    this.getVendorsTypesApiCallId = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.dashboardVendorEndPoint
    });
  };

  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    this.setState({
      anchorsElement: {
        ...this.state.anchorsElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (rowId: number) => {
    this.setState({
      anchorsElement: {
        ...this.state.anchorsElement,
        [rowId]: null
      },
    });
  };

  handleReminderStatus = async (formData: Object, reminderStatus: number)=> {
    this.updateRemindersStatus = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.reminderPutMethod,
      body: formData,
      endPoint: `${webConfigJSON.reminderActionsApEndPoint}${reminderStatus}`
    });
  };

  handleCompleted = (orderId: number, status: string) => {
    const formData = {
      reminder: {
        status: status
      }
    };
    this.handleReminderStatus(formData, orderId);
    this.handleMenuClose(orderId);
  };

  handleMessagesPopupClose = () => {
    this.setState({
      messagesPopup: false
    });
  };

  handleReminderRouting = async(reminderId: number, openKey: string) => {
    await storage.set("reminderId",reminderId)
    this.props.navigation.navigate(openKey,{reminderId});
  };

  getDeliquencyReport = async () => {
    this.deliquencyReportApiCallId = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.deliquencyApiEndPoint
    });
  };

  handleClosePreferredVendors = () => {
    this.setState({
      preferredVendorsModal: !this.state.preferredVendorsModal
    });
  };

  handleAddNewVendors = () => {
    const toNewVendors = new Message(getName(MessageEnum.NavigationMessage));
    toNewVendors.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VendorDashboardEdit"
    );
    toNewVendors.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toNewVendors);
  };

  getExistingDetails = async () => {
    this.getOldVendoApiCallId = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.oldVendorsApiEndPoint
    });
  };

  handleBlankDetails = (empty: boolean | string) => {
    return empty ? empty : webConfigJSON.slash
  };

  handleAddExistingVendor = (vendorId: number) => {
    const formData = new FormData();
    if (vendorId !== null) {
      this.setState(prevState => ({
        disabledVendorsStates: {
          ...prevState.disabledVendorsStates,
          [vendorId]: true
        }
      }));
      formData.append("vendor_account_id", vendorId.toString());
      this.addExistingVendors(formData);
    } else {
      this.setState({
        messagesType: "error",
        messagesPopup: true,
        messagesForPopup: "Vendor profile Id is not available!"
      });
    }
  };

  addExistingVendors = async (formData: FormData) => {
    this.postAddExistingVendorApiCall = await this.dashboardApiCall({
      method: webConfigJSON.postApiMethod,
      endPoint: webConfigJSON.postApprovedVendorApiEndPoint,
      body: formData,
      type: "formData"
    });
  };

  apiFailCallBack = (apiRequestCallID: string, responseJSON: InvalidResponseType) => {
    if (apiRequestCallID === this.postAddExistingVendorApiCall) {
      this.setState({
        messagesType: "error",
        messagesPopup: true,
        messagesForPopup: responseJSON.errors
      });
    }
    if (apiRequestCallID === this.getLandlordWorkOrder) {
      if(responseJSON.errors === "No work orders available for this landlord")
      this.setState({
        searchErrorWorkOrder: responseJSON.errors
      })
    }
    if (apiRequestCallID === this.getRemindersAPiCall) {
      if(responseJSON.errors === "No reminders available for this landlord")
      this.setState({
        searchErrorReminder: responseJSON.errors
      })
    }
    
  };

  getRenewalOutlook = async () => {
    this.getRenewalLookApiCallId = await this.dashboardApiCall({
      contentType: webConfigJSON.dashboarContentType,
      method: webConfigJSON.dashboarApiMethodType,
      endPoint: webConfigJSON.renewalApiEndPoint
    });
  };

  handleChangeVendorPagination = (value: object, page: number) => {
    this.setState({
      currentVendor: page,
      valueOfKey: value
    }, () => {
      this.getPreferredVendor(page);
    });
  };

  handleVendorPagination = () => {
    const vendorDetails = this.state.preferredVendor;
    if (vendorDetails.length < 1) {
      this.setState(prevState => ({
        currentVendor: Math.max(1, prevState.currentVendor - 1)
      }));
    }
  };

  // Customizable Area End
}
