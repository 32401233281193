import React, {Component} from 'react';
import { TextField, IconButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CalendarToday } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
      marginLeft: '25px',
      fontFamily: "Outfit",
    },
  }));

  interface CustomDatePickerProps {
    value: Date | null;
    onChange: (date: Date | null) => void;
    required?: boolean;
    disabled?: boolean;
  }
  
  class CustomDatePicker extends Component<CustomDatePickerProps> {
    state = {
      open: false,
    };

    handleChange = (newValue: Date | null) => {
      this.setState({ open: false });
      this.props.onChange(newValue);
    };

    handleOpen = () => {
      if(!this.props.disabled){
        this.setState({ open: true });
      }
    };
    handleTextFieldTouch = (event: React.TouchEvent) => {
      event.preventDefault();
      if(!this.props.disabled){
        this.setState({ open: true });
      }
    };

    handleIconClick = () => {
      if(!this.props.disabled){
      this.setState({open: !this.state.open})
      }
    };

    render() {
      const { value , required, disabled } = this.props;
  
      return (
        <ClickAwayListener onClickAway={() => this.setState({ open: false })} mouseEvent="onMouseDown">
          <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
            <CalendarToday
              style={{
                position: "absolute",
                left: "10px",
                top: "-5px",
                transform: "translateY(50%)",
                pointerEvents: "none",
                color: "#757575",
                backgroundColor: "white",
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date()}
                value={value}
                onChange={this.handleChange}
                open={this.state.open}
                onClose={() => this.setState({ open: false })}
                disabled={disabled}
                renderInput={(params) => (
                  <CustomTextField
                    required={required}
                    size="small"
                    fullWidth
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: 8 },
                    }}
                    placeholder="mm-dd-yyyy"
                    onClick={this.handleOpen}
                    onTouchStart={this.handleTextFieldTouch}
                    disabled={disabled}
                  />
                )}
                inputFormat="MM-dd-yyyy"
              />
            </LocalizationProvider>
            <IconButton
              onClick={this.handleIconClick}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#757575",
                backgroundColor: "white",
                height: "35px",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
            >
              <KeyboardArrowDownIcon/>
            </IconButton>
          </div>
        </ClickAwayListener>
      );
    }
  }
  
  export default CustomDatePicker;