import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,

  FormControl, Select, MenuItem, TextField, Divider,
  // Customizable Area End
  Link,
} from "@mui/material";

// Customizable Area Start
import FileUpload from "../src/FileUpload.web";
import { downArrow } from "../../blocks/portfoliomanagement/src/assets";
import { ChangeEvent } from 'react';
import {
  createTheme,
  ThemeProvider,
  Theme,
  
} from "@mui/material/styles";


import DocumentsFileUpload from "../../components/src/DocumentsFileUpload.web"
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  resize: vertical;
  height: 132px;
  marginTop: 3px;
  min-height: 132px;
  max-height: 132px;
  border: 1px solid #ccc;
  border-radius: 8px;
  '& .MuiInputBase-root': {
    color: '#000000',
  },
  '& .MuiInputLabel-root': {
    color: '#BAB1AB',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#BAB1AB',
    },
    '&:hover fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#BAB1AB',
    },
  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`;

const StyledTextFieldtwo = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: '#000000',
  },
  '& .MuiInputLabel-root': {
    color: '#BAB1AB',
  },
  '& .MuiOutlinedInput-root': {
    height:"44px",
    '& fieldset': {
      borderColor: '#BAB1AB',
    },
    '&:hover fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#BAB1AB',
    },
  },
}));
interface FileWithPreview extends File {
  preview: string;
}
interface CustomEnroll1Props {
  handleChangePersonal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChangetextArea: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  countriesCode: Array<{ name: string; emoji_flag: string; country_code: string }>;
  isCountriesDropdown: boolean; 
  handleCountryDropdownClose: () => void;
  handleCountryCodeDropdown: ()=> void;
  areaCodesState: string;
  handleCountryChange: (event: SelectChangeEvent<string>) => void;
  selectCountry: string;
  formData:{
   
    marketState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    propertyListingState:  (event: React.ChangeEvent<HTMLInputElement>) => void;
    phonenumberState:  (event: React.ChangeEvent<HTMLInputElement>) => void;
    areacodeState : (event: React.ChangeEvent<HTMLInputElement>) => void;
    descriptionState:   string;
    emailState: string
  }
 
  formErrors: {
    descriptionStateError:  JSX.Element;
    areacodeError: JSX.Element;
    phonenumberError: JSX.Element;
    listingTypeError: JSX.Element;
   
    marketStateError:JSX.Element;
    emailStateError: string;
    photoStateError: string

  };
  handlePhotoFilesChange: () => void;
  photoFile: FileWithPreview[]
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const lisingData = ["Lising 1", "Lising 2", "Lising 3"];
function DividerEnrolfirst() {
  return (
    <>
      <Grid container justifyContent="flex-end" sx={{width: {xs:"83%"}, marginTop: {  sm: "3rem" }, margin: {xs: "1rem 2rem"} }}>
        <Grid item md={12}  xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Divider sx={{ width: '100%' }} />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
// Customizable Area End



export default class CustomEnrol2 extends  React.Component<CustomEnroll1Props> {


  // Customizable Area Start
  // Customizable Area End

  render() {
    const { handleChangePersonal,countriesCode, selectCountry, areaCodesState, handleCountryDropdownClose, isCountriesDropdown, handleCountryCodeDropdown, handleCountryChange, formData,formErrors ,handleSelectChange,handleSelectChangetextArea, handlePhotoFilesChange, photoFile } = this.props;
  
  
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={{ display: "flex" }}>
           <Grid container style={{ padding: "2rem" }} >
            <Grid item md={3} xs={12}>
              <Box  >
                <Typography style={webStyle.Marketingtext}>Marketing <br />information</Typography>
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>

              <Box>
                 <label style={webStyle.textstylechange}>Property marketing name</label>
                <StyledTextFieldtwo
                data-testId="property-market"
                 value={formData.marketState}
                 onChange={handleChangePersonal}
                 name="marketState"

                  variant="outlined"
                  fullWidth
                  sx={webStyle.StyledTextFieldtwomanage}
                />
              </Box>
              

              {formErrors.marketStateError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.marketStateError}</span>
              )}

              <Box style={webStyle.marginBorderstyle1}>
                <label style={webStyle.textstylechange}>Marketing description</label>
             
                <StyledTextarea
                data-testId="desc-field"
                sx={webStyle.aretextstyle}
                minRows={3}
                maxLength={100}
                value={formData.descriptionState}
                name="descriptionState"
                onChange={handleSelectChangetextArea}
              />
              <Typography sx={webStyle.floatingText}>
                  {formData.descriptionState.length}/100
                </Typography>
              </Box>
              {formErrors.descriptionStateError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.descriptionStateError}</span>
              )}
              <Box style={webStyle.marginBorderstyle1}>
                <Box style={{ borderRadius: "8px", position: "relative" }}>
                  <label style={webStyle.textstylechange}>Lising type</label>
                  <StyledTextFieldtwo
                    select
                    fullWidth
                    data-testId="lising-select"
                    name="propertyListingState"
                    InputLabelProps={{ shrink: false }}
                    value={formData?.propertyListingState || ""}
                    onChange={handleSelectChange}
                    style={{ paddingRight: "30px" }} 
                    SelectProps={{
                      IconComponent: () => null,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        appearance: "none", 
                      },
                    }}
                  >
                    {lisingData.map((propData, index) => (
                      <MenuItem key={index} value={propData}>{propData}</MenuItem>
                    ))}
                  </StyledTextFieldtwo>
                  <img
                    style={{
                      position: "absolute",
                      right: "13px",
                      top: "64%",
                      pointerEvents: "none"
                    }}
                    src={downArrow}
                    alt="down"
                  />
                </Box>
                {formErrors.listingTypeError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.listingTypeError}</span>
              )}
              </Box>
              <Box style={webStyle.marginBorderstyle1}>
                <label style={webStyle.textstylechange}>Photos</label>
                <FileUpload
                  label=""
                  name="formW9Files"
                  files={photoFile}
                  onFilesChange={handlePhotoFilesChange}
                  />
                  {formErrors.photoStateError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.photoStateError}</span>
              )}
              </Box>


            </Grid>
          </Grid>
        </Box>

        <DividerEnrolfirst />

        <Grid container style={{ padding: "2rem" }} >
          <Grid item md={3} xs={12}>
              <Box  >
                <Typography style={webStyle.Marketingtext}>Vacancy Posting <br /> Contact Info</Typography>
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
            <Box>
              <label style={webStyle.textstylechange}>Email address</label>
                <StyledTextFieldtwo
                  data-testId="email-field"
                  value={formData.emailState}
                  onChange={handleChangePersonal}
                  name="emailState"
                  variant="outlined"
                  fullWidth
                  sx={webStyle.StyledTextFieldtwomanage}
                />
                <label style={webStyle.emailtext}>example@example.com</label>
            </Box>
              {formErrors.emailStateError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.emailStateError}</span>
              )}
              <Grid container spacing={4}>
                <Grid item md={4}>
                  <Box style={webStyle.marginBorderstyle1} display={"flex"} flexDirection={"column"}>
                    <label style={webStyle.textstyleschange}>Area Code</label>
                  <Select
                    required
                    data-testId="area-field"
                    name="countryCode"
                    variant="outlined"
                    size="medium"
                    value={areaCodesState}
                    onClose={handleCountryDropdownClose}
                    onChange={handleCountryChange}
                    onOpen={handleCountryCodeDropdown}
                    sx={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      height: "47px"
                    }}
                  >
                    <MenuItem value="">{selectCountry}</MenuItem>
                    {countriesCode?.map((country) => (
                      <MenuItem key={country.name} value={country.country_code}>
                        <span style={{ display: isCountriesDropdown ? "block" : "none" }}>
                          {country.emoji_flag} {country.name} (+{country.country_code})
                        </span>
                        <span style={{ display: isCountriesDropdown ? "none" : "block" }}>+{country.country_code}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  </Box>
                  {formErrors.areacodeError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.areacodeError}</span>
              )}
                </Grid>
                <Grid item md={8}>
                  <Box style={webStyle.marginBorderstyle1}>
                    <label style={webStyle.textstylechange}>Phone number</label>
                    <StyledTextFieldtwo
                      data-testId="phone-field"
                      value={formData.phonenumberState}
                      onChange={handleChangePersonal}
                      name="phonenumberState"
                      variant="outlined"
                      fullWidth
                      sx={webStyle.StyledTextFieldtwomanage}
                    />
                  </Box>
                  {formErrors.phonenumberError && (
                <span style={webStyle.ErrorStylelandtwo}>{formErrors.phonenumberError}</span>
              )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  ErrorStylelandtwo:{
    color:'rgb(211, 47, 47)',
    fontWeight:400,
    fontSize:"0.75rem",
    fontFamily:"Outfit"
  },
  aretextstyle:{
    height:"132px",
    marginTop:"3px",
    minHeight: "132px",
    maxHeight: "132px",
    resize: "vertical",
  },
  StyledTextFieldtwomanage :{
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      height:"44px"
        
    }
  },
  marginBorderstyle1: {
    borderRadius: "8px", marginTop: "2rem",
  },
  textstylechange: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    marginBottom: "2rem",
    color:"#544B45"
  },
  floatingText: {
    textAlign: "end",
    color: "#64748B",
    borderRadius: "8px",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 400,
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  textstyleschange: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Outfit",
    color:"#544B45"
  },
  Marketingtext: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    fontFamily: "Outfit",
    color: "#BAB1AB"

  },
  addstyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    color: '#CC9200'

  },
 
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    marginTop: 20,
    marginBottom: 20,
  },
  fileWrapper: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    flex: 1,
  },
  submitBtnStyle: {
    marginTop: 20,
  },
  uploadBtnWrapper: {
    display: "flex",
    marginTop: 20,
  },
  uploadBtn: {
    padding: 7,
    paddingLeft: 15,
    paddingRight: 15,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#fff",
    borderRadius: 5,
    cursor: "pointer",
    marginRight: 15,
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  bulkFileWrapper: {
    border: "1px solid #ccc",
    padding: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  bulkFileTopWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textWrapper: {
    flex: 1,
  },
  fileItemWrapper: {
    display: "flex",
    border: "1px solid #ccc",
    padding: 10,
    marginTop: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  emailtext: {
    borderRadius: "8px", 
    marginTop: "2rem",
    fontSize: "14px",
    fontWeight: 700,
    color: "#A3978F",
    fontFamily: "Outfit",
  },
};
// Customizable Area End
